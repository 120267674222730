.container {
  border-bottom: 1px solid #f1f1f1;
  box-sizing: border-box;
  padding: 24px 64px;
  background: #fff;
  position: sticky;
  top: 64px;
  z-index: 9000;

  margin: 0 auto;
  width: 100%;
  max-width: 1500px;
}

.containerSP {
  padding: 0;
}

@media screen and (max-width: 1080px) {
  .container {
    border-bottom: none;
    position: initial;
    width: 100%;
    padding: 0;
    padding-top: 40px;
  }

  .containerSP {
    position: fixed;
    box-sizing: border-box;
    width: 100%;
    top: 64px;
    border-bottom: 1px solid #f1f1f1;
    z-index: 9001;
    background-color: #fff;
    padding: 0 8px;
  }
}

@media screen and (max-width: 768px) {
  .container {
    padding: 0;
    padding-top: 48px;
  }

  .containerSP {
    position: fixed;
    width: 100%;
    top: 40px;
    padding: 0;
    border-bottom: 1px solid #f1f1f1;
    z-index: 9001;
    background-color: #fff;
  }
}
