.container {
  padding-top: 64px;
}

.section {
  margin-top: 64px;
  padding-bottom: 64px;
  text-align: center;
  border-bottom: 1px solid #eaeaea;
}

.section:last-child {
  border-bottom: unset;
}

.box {
  width: 480px;
  margin: 24px auto 0;
  text-align: left;
}

.boxMarginBottom {
  composes: box;
  margin-bottom: 48px;
}

.boxImg {
  composes: box;
  display: flex;
  align-items: center;
}

.emailArea {
  margin-bottom: 48px;
}

.ttl {
  font-size: 18px;
  text-align: center;
  margin-bottom: 32px;
}

.icon {
  width: 80px;
  height: 80px;
  border: 1px solid #c4c4c4;
  border-radius: 50px;
  text-align: center;
  overflow: hidden;
}

.icon img {
  width: 100%;
}

.label {
  display: block;
  color: #333;
  font-size: 12px;
  font-weight: bold;
  margin-top: 24px;
}

.labelFlex {
  composes: label;
  display: flex;
  white-space: nowrap;
}

.spacer {
  width: 100%;
  flex-grow: 1;
  flex-shrink: 1;
}

.input {
  font-size: 14px;
  border: solid 1px #c4c4c4;
  border-radius: 8px;
  box-sizing: border-box;
  margin-top: 8px;
  padding: 0 16px;
  width: 100%;
  height: 40px;
}

.input:hover {
  background: #fff;
  border: solid 1px #9281ff;
  box-shadow: 0px 0px 8px rgba(146, 129, 255, 0.5);
  transition: 0.3s;
}

.input:focus {
  outline: none;
  background: #fff;
  border: solid 1px #9281ff;
  box-shadow: 0px 0px 8px rgba(146, 129, 255, 0.5);
  transition: 0.3s;
}

.description {
  -webkit-appearance: none;
  appearance: none;
  background: #fff;
  border: solid 1px #c4c4c4;
  border-radius: 8px;
  box-sizing: border-box;
  width: 100%;
  margin-top: 8px;
  padding: 16px;
}

.description:hover {
  background: #fff;
  border: solid 1px #9281ff;
  box-shadow: 0px 0px 8px rgba(146, 129, 255, 0.5);
  transition: 0.3s;
}

.description:focus {
  outline: none;
  background: #fff;
  border: solid 1px #9281ff;
  box-shadow: 0px 0px 8px rgba(146, 129, 255, 0.5);
  transition: 0.3s;
}

.remove {
  font-size: 14px;
  margin-top: 0;
}

.remove .unlink {
  color: #2f80ed;
  text-decoration: underline;
  cursor: pointer;
}

.removeGoogle,
.removeTwitter {
  composes: remove;
}

.note {
  color: #999;
  font-size: 12px;
  line-height: 160%;
  margin-top: 8px;
}

.btn {
  background: #9281ff;
  border: none;
  border-radius: 8px;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  height: 40px;
  margin-top: 32px;
  padding: 2px 16px 0;
  transition: 0.3s;
}

.btnSmallMargin {
  composes: btn;
  margin-top: 16px;
}

.btnNoMargin {
  composes: btn;
  margin-top: 0;
}

.btn:hover {
  opacity: 0.8;
  transition: 0.3s;
}

.btn:active {
  opacity: 0.3;
}

.btn svg {
  margin-right: 4px;
  vertical-align: middle;
}

.btnImg {
  composes: btn;
  background: #fff;
  border: 1px solid #9281ff;
  color: #9281ff;
  margin-top: 0;
  margin-left: 24px;
}

.btnImg:hover {
  background: #9281ff;
  color: #fff;
  opacity: 1;
}

.btnImg:active {
  opacity: 0.3;
}

.btnCancel {
  composes: btn;
  background: #f1f1f1;
  border: none;
  color: #333;
  margin-top: 0;
  margin-left: 16px;
}

.btnCancel:hover {
  background: #c4c4c4;
  color: #fff;
  opacity: 1;
}

.btnCancel:active {
  opacity: 0.3;
}

.btnGoogle {
  composes: btn;
  background: #fff;
  border: 1px solid #c4c4c4;
  color: #333;
  margin-top: 0;
}

.btnTwitter {
  composes: btn;
  background: #00acee;
  border: none;
  color: #fff;
  margin-top: 0;
}

.coment {
  font-weight: normal;
  margin-left: 4px;
}

.coment[data-over="true"] {
  color: #eb5757;
}

@media screen and (max-width: 768px) {
  .container {
    padding-top: 32px;
  }

  .box {
    width: 100%;
    padding: 0 16px;
    box-sizing: border-box;
  }
}

/* Loader */
.loading {
  display: inline-flex;
  background: #ccc;
  border: none;
  border-radius: 8px;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  height: 40px;
  margin-top: 32px;
  padding: 2px 16px 0;
  transition: 0.5s;

  justify-content: center;
  align-items: center;
  box-sizing: border-box;

  user-select: none;
}

.loader {
  margin-top: 8px;
  margin-left: -4px;
  vertical-align: top;
  display: inline-block;
  position: relative;
  width: 16px;
}
.loader div {
  margin-top: -1px;
  position: absolute;
  top: 50%;
  width: 2px;
  height: 2px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.loader div:nth-child(1) {
  left: 8px;
  animation: loader1 0.6s infinite;
}
.loader div:nth-child(2) {
  left: 8px;
  animation: loader2 0.6s infinite;
}
.loader div:nth-child(3) {
  left: 12px;
  animation: loader2 0.6s infinite;
}
.loader div:nth-child(4) {
  left: 16px;
  animation: loader3 0.6s infinite;
}

@keyframes loader1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes loader3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes loader2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(4px, 0);
  }
}
