.templates {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 8px;
  margin-top: 16px;
  padding: 0;
  text-align: left;
}

.canvas {
  display: none;
}

.new {
  cursor: pointer;
  width: 100%;
  background-color: #f8f8f8;
  border: 1px dashed #c4c4c4;
  border-radius: 4px;
  transition: 0.3s;

  display: flex;
  align-items: center;
  justify-content: center;
}

.new input {
  display: none;
}

.btn {
  cursor: pointer;
  width: 100%;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  transition: 0.3s;
}

.new:hover,
.btn:hover {
  border: 1px solid #9281ff;
  background-color: #fff;
  box-shadow: 0px 0px 8px rgba(146, 129, 255, 0.5);
}
