/* LazyImage */

.ImageNode__LazyImage_root {
  width: 100%;
  box-sizing: border-box;
  border: 2px solid transparent;
  cursor: pointer;
}

.ImageNode__LazyImage_root[data-size="small"] {
  width: 50%;
}

.ImageNode__LazyImage_root[data-focused="true"] {
  border-color: #00f;
}

.ImageNode__LazyImage_root[data-focused="true"] {
  border-color: #00f;
}

.ImageNode__LazyImage_root[data-draggable="true"] {
  cursor: grab;
}

.ImageNode__LazyImage_root[data-draggable="true"]:active {
  cursor: grabbing;
}

/* ImageComponent */

.ImageNode__ImageComponent_root {
  position: relative;
}

.ImageNode__ImageComponent_button {
  visibility: hidden;
  user-select: none;
  cursor: pointer;
  position: absolute;
  top: 8px;
  left: 8px;

  padding: 4px;

  fill: #fff;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.2);
}

@media (hover: hover) {
  .ImageNode__ImageComponent_button:hover {
    background-color: rgba(0, 0, 0, 0.5);
  }

  .ImageNode__ImageComponent_root:hover .ImageNode__ImageComponent_button {
    visibility: visible;
  }
}

@media (hover: none) {
  .ImageNode__ImageComponent_button {
    visibility: visible;
  }

  .ImageNode__ImageComponent_button:active {
    background-color: rgba(0, 0, 0, 0.5);
  }
}
