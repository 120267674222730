.EditorTheme__paragraph {
  margin: 4px 0;
  line-height: 180%;
}

.EditorTheme__quote {
  margin-left: 0;
  margin-right: 0;
  padding: 12px 18px;
  border-radius: 8px;
  background-color: #f5f5f5;

  font-size: 12px;
  color: #777;
  line-height: 160%;
}

.EditorTheme__textUnderline {
  text-decoration: underline;
}

.EditorTheme__h1 {
  font-size: 32px;
  font-weight: 400;
  line-height: 160%;
  word-break: break-word;
  margin: 2.8rem 0 16px;
  padding-bottom: 8px;
  border-bottom: 1px solid #e2e2e2;
}

.EditorTheme__h2 {
  font-size: 24px;
  font-weight: 400;
  line-height: 160%;
  margin: 2.4rem 0 0;
  word-break: break-word;
}

.EditorTheme__h3 {
  font-size: 20px;
  font-weight: 400;
  line-height: 160%;
  margin: 2rem 0 0;
  word-break: break-word;
}

.EditorTheme__h1:first-child,
.EditorTheme__h2:first-child,
.EditorTheme__h3:first-child {
  margin-top: 0;
}

@media screen and (max-width: 414px) {
  .EditorTheme__paragraph {
    font-size: 14px;
    line-height: 160%;
  }

  .EditorTheme__h1 {
    font-size: 24px;
    line-height: 140%;
    padding-bottom: 4px;
    margin-bottom: 8px;
  }

  .EditorTheme__h2 {
    font-size: 18px;
    line-height: 140%;
  }

  .EditorTheme__h3 {
    font-size: 16px;
    line-height: 140%;
  }
}
