.container {
  box-sizing: border-box;
  margin: 75px 0 0;
}

@media screen and (max-width: 768px) {
  .container {
    display: block;
    margin: 24px 0 0;
    padding: 0 16px;
    box-sizing: border-box;
  }
}

.box {
  border: 1px solid #eaeaea;
  border-radius: 8px;
  box-sizing: border-box;
}

.top {
  padding: 24px;
  border-bottom: 1px solid #eaeaea;
}

.bottom {
  padding: 16px 24px 24px;
}

.price {
  font-size: 32px;
  margin: 4px 0 0;
}

.fee {
  font-size: 12px;
  margin: 4px 0 0;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 16px 0 0;
}

.flexSP {
  display: block;
}

@media screen and (max-width: 768px) {
  .flexSP {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 8px 0 0;
  }
}

.bannerArea {
  margin-top: 44px;
  text-align: center;
}

.bannarCopy {
  font-size: 14px;
  line-height: 1.4;
  margin: 0;
}

.bannarBtn {
  display: inline-block;
  background: #2ad1ff;
  border: none;
  border-radius: 4px;
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  line-height: 1.2;
  cursor: pointer;
  text-decoration: none;

  width: 100%;
  max-width: 180px;
  margin-top: 8px;
  padding: 12px 0;

  position: relative;
}

.bannarBtn::after {
  content: "";
  display: inline-block;

  background: url(./BannarArrowWhite.svg) no-repeat;
  background-size: 100%;

  width: 6px;
  height: 14px;

  position: absolute;
  top: 50%;
  right: 4%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
}

@media screen and (max-width: 768px) {
  .bannerArea {
    display: none;
  }

  .bannarBtn {
    background: #fff;
    border: #2ad1ff 1px solid;
    border-radius: 0;
    color: #333;
    text-align: center;

    max-width: 100%;
    margin-top: 24px;
    padding: 8px 0;
  }

  .bannarBtn span {
    font-size: 12px;
    font-weight: normal;
  }

  .bannarBtn::after {
    background: url(./BannarArrowBlack.svg) no-repeat;
    background-size: 100%;
  }
}

.spll {
  font-size: 10px;
  margin: 0;
}

.number {
  font-size: 12px;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  margin: 0;
  padding: 3px 4px 2px;
}

.update,
.date {
  font-size: 10px;
  margin: 0;
}

.action {
  list-style: none;
  margin: 24px 0 0;
  padding: 0;
  text-align: center;
}

.buttonAction {
  background: #fff;
  border: 1px solid #c4c4c4;
  border-radius: 50px;
  cursor: pointer;
  font-size: 12px;
  color: #333;
  margin: 12px auto 0;
  padding: 4px 16px;
  width: 120px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s cubic-bezier(0.22, 1, 0.36, 1);
}

.buttonAction svg {
  fill: #333;
  margin-right: 4px;
  transition: 0.4s cubic-bezier(0.22, 1, 0.36, 1);
}

.buttonActionActive {
  composes: buttonAction;
  background: #f1f1f1;
  border-color: #f1f1f1;
  color: #9281ff;
}

.buttonActionActive svg {
  fill: #9281ff;
}

.help {
  background: none;
  border: none;
  font-size: 11px;
  color: #999;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: underline;
  width: 100%;
  transition: 0.3s cubic-bezier(0.22, 1, 0.36, 1);
  opacity: 1;
  padding: 0;
}

.pcb {
  display: block;
}

@media screen and (max-width: 768px) {
  .pcb {
    display: none;
  }
}

.spb {
  display: none;
}

@media screen and (max-width: 768px) {
  .spb {
    display: block;
  }
}

@media (hover: hover) {
  .buttonAction:hover {
    border: 1px solid #9281ff;
    color: #9281ff;
    transition: 0.3s cubic-bezier(0.22, 1, 0.36, 1);
  }
  .buttonAction:hover svg {
    fill: #9281ff;
    transition: 0.4s cubic-bezier(0.22, 1, 0.36, 1);
  }
  .buttonAction:active {
    opacity: 0.2;
    transition: opacity 0.4s cubic-bezier(0.22, 1, 0.36, 1);
  }
  .help:hover {
    opacity: 0.7;
    transition: 0.3s cubic-bezier(0.22, 1, 0.36, 1);
  }
  .help:active {
    opacity: 0.2;
    transition: 0.4s cubic-bezier(0.22, 1, 0.36, 1);
  }
  .bannarBtn:hover {
    opacity: 0.7;
    transition: opacity 0.3s cubic-bezier(0.22, 1, 0.36, 1);
  }
}
