.list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  grid-gap: 24px;
  margin: 0;
  padding: 0;
}

.cards {
  border: 1px solid #eaeaea;
  border-radius: 8px;
  box-sizing: border-box;
  list-style: none;
  padding-bottom: 16px;
  overflow: hidden;
  cursor: pointer;
  position: relative;
  transition: 0.2s;
}

.cards:hover {
  border: 1px solid #9281ff;
  box-shadow: 0px 0px 12px rgba(146, 129, 255, 0.5);
}

.cards:nth-child(4n + 1) {
  margin-left: 0;
}

.cards a {
  text-decoration: unset;
  color: #333;
}

.thumnail {
  width: 100%;
  padding-top: 56.25%;
  position: relative;
  overflow: hidden;
}

.thumnail img {
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
}

.category {
  color: #9281ff;
  font-size: 12px;
  margin: 12px 0 0 0;
  padding: 0 16px;
}

.ttl {
  font-size: 16px;
  font-weight: normal;
  margin: 4px 0 0 0;
  padding: 0 16px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-align: justify;
}

@media screen and (max-width: 768px) {
  .list {
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
    grid-gap: 16px;
    padding: 0 16px;
  }
  .cards {
    padding-bottom: 10px;
  }

  .category {
    padding: 0 8px;
    font-size: 10px;
  }

  .ttl {
    font-size: 14px;
    padding: 0 8px;
  }
}
