.container {
  box-sizing: border-box;
}

.header {
  width: 960px;
  margin: 64px auto 0;
  box-sizing: border-box;
}

.ttl {
  color: #333;
  font-size: 18px;
  text-align: center;
  margin: 0;
}

.nav {
  border-bottom: 1px solid #f1f1f1;
}

.list {
  width: 960px;
  margin: 64px auto 0;
  display: flex;
  list-style: none;
  padding: 0;
  box-sizing: border-box;
}

.list li {
  font-size: 14px;
  color: #999;
  cursor: pointer;
  padding: 0 32px;
  height: 40px;
  border-bottom: 4px solid #fff;
  line-height: 1.4;
  transition: 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.list li:hover {
  color: #9281ff;
  font-weight: bold;
}

.list li.active {
  color: #9281ff;
  font-weight: bold;
  border-bottom: 4px solid #9281ff;
}

.alert {
  display: none;
  vertical-align: middle;
  margin-left: 2px;
}

.alert[data-checked="true"] {
  display: inline-block;
}

.spi {
  display: none;
}

@media screen and (max-width: 768px) {
  .header {
    width: 100%;
    padding: 0 16px;
  }

  .list {
    width: 100%;
    margin-top: 32px;
  }

  .list li {
    width: 100%;
    text-align: center;
    height: 48px;
    padding: 0 16px;
  }

  .spi {
    display: inline-block;
  }
}
