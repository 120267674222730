.Modal__body {
  margin: 100px auto;
  width: calc(100% - 16px);
  max-width: 600px;
  z-index: 10001;
  box-sizing: border-box;

  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;

  padding: 16px;
  animation: fade 0.25s ease-out;
}

.Modal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
  overflow-x: hidden;
  overflow-y: scroll;

  background-color: rgba(30, 30, 30, 0.5);
  animation: fade 0.25s ease-out;
}

@keyframes fade {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
