.list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  grid-gap: 48px;
  margin-top: 24px;
  padding: 0;
  text-align: left;
}

.cards {
  border: 1px solid #eaeaea;
  border-radius: 8px;
  box-sizing: border-box;
  list-style: none;
  overflow: hidden;
  cursor: pointer;
  position: relative;
}

.cards:hover {
  border: 1px solid #9281ff;
  box-shadow: 0px 0px 12px rgba(146, 129, 255, 0.5);
  transition: 0.3s;
}

.cards > a {
  text-decoration: none;
  color: unset;
}

.thumnail {
  width: 100%;
  padding-top: 56.25%;
  position: relative;
  overflow: hidden;
}

.thumnail img {
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
}

.like {
  display: flex;
  align-items: center;
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  font-size: 14px;
  padding: 8px;
  position: absolute;
  left: 0;
  bottom: 16px;
  cursor: pointer;
  transition: 0.5s;
  z-index: 2;
}

.like svg {
  margin-right: 4px;
}

.like:hover {
  opacity: 0.8;
  transition: 0.5s;
}

.category {
  color: #9281ff;
  font-size: 12px;
  margin: 12px 0 0 0;
  padding: 0 16px;
  line-height: 140%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.ttl {
  font-size: 16px;
  font-weight: normal;
  margin: 4px 0 0 0;
  padding: 0 16px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.description {
  color: #999;
  font-size: 12px;
  line-height: 140%;
  margin: 6px 0 0 0;
  padding: 0 16px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  min-height: 2rem;
}

.hover {
  opacity: 0;
  color: #fff;
  background: rgba(146, 129, 255, 0.95);
  box-sizing: border-box;
  padding: 24px 16px;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  transition: 0.3s;
}

.hover:hover {
  opacity: 1;
  transition: 0.3s;
}

.hover .ttl {
  font-weight: bold;
  padding: 0;
}

.writer {
  font-size: 12px;
  margin: 8px 0 0 0;
}

.player,
.times {
  font-size: 14px;
}

.player {
  margin: 16px 0 0 0;
}

.times {
  margin: 8px 0 0 0;
}

.tagArea {
  display: flex;
  flex-wrap: wrap;
  margin-top: 16px;
  padding: 0;
  overflow: hidden;
}

.tag {
  background: none;
  border: 1px solid #fff;
  border-radius: 4px;
  font-size: 12px;
  list-style: none;
  margin-top: 8px;
  margin-right: 8px;
  height: fit-content;
  transition: 0.5s;
}

/* .tag:hover {
  background: rgba(255, 2555, 255, 0.3);
  transition: 0.5s;
} */

.tag span {
  display: inline-block;
  color: #fff;
  padding: 4px 4px 2px;
  text-decoration: none;
}

.btnArea {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  right: 16px;
  bottom: 24px;
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f3f3f3;
  border-radius: 8px;
  list-style: none;
  width: 32px;
  height: 32px;
  margin-left: 8px;
  transition: 0.5s;
}

.btn:hover {
  opacity: 0.7;
  transition: 0.5s;
}

.btn svg {
  fill: #333;
}

.btnLiked {
  composes: btn;
}

.btnLiked svg {
  fill: #eb5757;
  animation-name: checked;
  animation-fill-mode: forwards;
  animation-duration: 0.2s;
  animation-iteration-count: 1;
  animation-timing-function: easeOutQuint;
}

.btnRead,
.btnBookmark,
.btnLike {
  composes: btn;
}

.btnRead:hover::before {
  content: "シナリオを読む";
  background: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  display: inline-block;
  font-size: 12px;
  padding: 4px;
  text-align: center;
  width: 88px;
  position: absolute;
  top: -30px;
  z-index: 50;
}

.btnBookmark:hover::before {
  content: "本棚に登録";
  background: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  display: inline-block;
  font-size: 12px;
  text-align: center;
  padding: 4px;
  width: 64px;
  position: absolute;
  top: -30px;
  z-index: 50;
}

.btnLike:hover::before {
  content: "いいね";
  background: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  display: inline-block;
  font-size: 12px;
  text-align: center;
  padding: 4px;
  width: 40px;
  position: absolute;
  top: -30px;
  z-index: 50;
}

.btnRead[data-checked="true"] svg {
  fill: #eb5757;
  animation-name: checked;
  animation-fill-mode: forwards;
  animation-duration: 0.2s;
  animation-iteration-count: 1;
  animation-timing-function: easeOutQuint;
}

.btnBookmark[data-checked="true"] svg {
  fill: #eb5757;
  animation-name: checked;
  animation-fill-mode: forwards;
  animation-duration: 0.2s;
  animation-iteration-count: 1;
  animation-timing-function: easeOutQuint;
}

.btnLike[data-checked="true"] svg {
  fill: #eb5757;
  animation-name: checked;
  animation-fill-mode: forwards;
  animation-duration: 0.2s;
  animation-iteration-count: 1;
  animation-timing-function: easeOutQuint;
}

@keyframes checked {
  0% {
    -moz-transform: scale(1, 1);
    -webkit-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
  }

  50% {
    -moz-transform: scale(1.3, 1.3);
    -webkit-transform: scale(1.3, 1.3);
    -o-transform: scale(1.3, 1.3);
    -ms-transform: scale(1.3, 1.3);
  }

  100% {
    -moz-transform: scale(1, 1);
    -webkit-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
  }
}

.btnBookmark[data-checked="true"]:hover::before {
  content: "本棚から削除";
  width: 72px;
}

.bottom {
  position: relative;
}

.pay {
  border-top: 1px dashed #f1f1f1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style: none;
  margin-top: 8px;
  padding: 6px 16px 4px;
}

.status {
  font-size: 12px;
  color: #c4c4c4;
  display: flex;
  justify-content: center;
  align-items: center;
}

.status svg {
  margin-right: 2px;
}

.price {
  font-size: 14px;
}

@media screen and (max-width: 768px) {
  .list {
    padding: 0 16px;
  }

  .hover .ttl {
    font-size: 24px;
  }

  .writer {
    font-size: 14px;
  }

  .player,
  .times {
    font-size: 16px;
  }

  .btn {
    width: 40px;
    height: 40px;
  }

  .pay {
    padding: 6px 16px;
  }
}

@media screen and (max-width: 414px) {
  .description {
    min-height: auto;
  }
}
