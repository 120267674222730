.container {
  background: #fff;
  box-sizing: border-box;
  padding-bottom: 80px;
}

.ttl {
  font-size: 18px;
  margin: 64px 0 0;
  padding: 0;
  text-align: center;
}

.box {
  box-sizing: border-box;
  margin: 64px auto;
  padding: 0;
  width: 640px;
}

.box:first-child {
  margin-top: 0;
}

.errorMessage {
  composes: box;
  margin: 32px auto;
  font-size: 14px;
  color: #eb5757;
}

.label {
  display: block;
  font-size: 12px;
  font-weight: bold;
  margin-top: 32px;
  margin-bottom: 8px;
}

.selectArea {
  position: relative;
  width: 250px;
}

.selectArea:after {
  content: "";
  display: block;
  background: url(./IconArrowDrop.svg);
  background-size: cover;
  width: 10px;
  height: 10px;
  position: absolute;
  top: 24px;
  right: 16px;
}

.select {
  -webkit-appearance: none;
  appearance: none;
  background: #fff;
  border: solid 1px #c4c4c4;
  border-radius: 8px;
  color: #333;
  cursor: pointer;
  width: 100%;
  height: 40px;
  margin-top: 8px;
  padding: 0 16px;
}

.select:focus {
  outline: none;
  background: #fff;
  border: solid 1px #9281ff;
  box-shadow: 0px 0px 8px rgba(146, 129, 255, 0.5);
  transition: 0.3s;
}

.select:hover {
  background: #fff;
  border: solid 1px #9281ff;
  box-shadow: 0px 0px 8px rgba(146, 129, 255, 0.5);
  transition: 0.3s;
}

.input {
  font-size: 14px;
  border: solid 1px #c4c4c4;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 0 16px;
  width: 100%;
  height: 40px;
}

.input:hover {
  background: #fff;
  border: solid 1px #9281ff;
  box-shadow: 0px 0px 8px rgba(146, 129, 255, 0.5);
  transition: 0.3s;
}

.input:focus {
  outline: none;
  background: #fff;
  border: solid 1px #9281ff;
  box-shadow: 0px 0px 8px rgba(146, 129, 255, 0.5);
  transition: 0.3s;
}

.genreBtn {
  background: #fff;
  border: 1px solid #c4c4c4;
  border-radius: 8px;
  color: #333;
  border-radius: 8px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 14px;
  height: 40px;
  padding: 0 32px 0 16px;
  transition: 0.5s;
  width: 100%;
  position: relative;
}

.genreBtn:after {
  content: "";
  display: block;
  background: url(./IconArrowDrop.svg);
  background-size: cover;
  width: 10px;
  height: 10px;
  position: absolute;
  top: 15px;
  right: 16px;
}

.genreBtn:hover {
  background: #fff;
  border: solid 1px #9281ff;
  box-shadow: 0px 0px 8px rgba(146, 129, 255, 0.5);
  transition: 0.3s;
}

.genreBtn:active {
  background: #fff;
  border: solid 1px #9281ff;
  box-shadow: 0px 0px 8px rgba(146, 129, 255, 0.5);
  transition: 0.3s;
}

.genreBtn:focus {
  outline: none;
  background: #fff;
  border: solid 1px #9281ff;
  box-shadow: 0px 0px 8px rgba(146, 129, 255, 0.5);
  transition: 0.3s;
}

.description,
.copyright {
  -webkit-appearance: none;
  appearance: none;
  background: #fff;
  border: solid 1px #c4c4c4;
  border-radius: 8px;
  box-sizing: border-box;
  width: 100%;
  padding: 12px 16px;
  font-family: inherit;
}

.description:hover,
.copyright:hover {
  background: #fff;
  border: solid 1px #9281ff;
  box-shadow: 0px 0px 8px rgba(146, 129, 255, 0.5);
  transition: 0.3s;
}

.description:focus,
.copyright:focus {
  outline: none;
  background: #fff;
  border: solid 1px #9281ff;
  box-shadow: 0px 0px 8px rgba(146, 129, 255, 0.5);
  transition: 0.3s;
}

.price {
  position: relative;
}

.price:before {
  display: block;
  content: "¥";
  color: #999;
  font-size: 20px;
  position: absolute;
  top: 8px;
  left: 14px;
}

.inputPrice {
  composes: input;
  width: 250px;
  padding-left: 32px;
}

.submitArea {
  display: flex;
  justify-content: center;
}

.btn {
  background: #9281ff;
  border: 1px solid #9281ff;
  border-radius: 8px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  height: 40px;
  margin: 48px auto 0;
  padding: 0 16px;
  transition: 0.3s;
}

.btn svg {
  margin-right: 4px;
}

.btn svg {
  fill: #9281ff;
}

.btn:hover {
  opacity: 0.8;
  transition: 0.3s;
}

.btn:active {
  opacity: 0.3;
}

.btn:hover svg {
  fill: #fff;
  transition: 0.3s;
}

.btnDistributor {
  composes: btn;
  background: #fff;
  border: 1px solid #c4c4c4;
  color: #333;
  margin: 8px 0 0;
  text-decoration: none;
  display: inline-flex;
}

.btnDistributor:hover {
  border: 1px solid #9281ff;
  color: #9281ff;
  transition: 0.3s;
}

.breakdown {
  display: flex;
  font-size: 12px;
  margin: 8px 0 0;
}

.breakdown dt {
  margin: 0;
  padding-left: 1rem;
  width: 80px;
}

.breakdown dd {
  margin: 0;
}

.radioArea {
  display: flex;
  align-items: center;
  font-size: 14px;
  border: solid 1px #c4c4c4;
  border-radius: 8px;
  box-sizing: border-box;
  margin-top: 8px;
  padding: 0 16px;
  width: 250px;
  height: 40px;
  cursor: pointer;
}

.radioArea:hover {
  background: #fff;
  border: solid 1px #9281ff;
  box-shadow: 0px 0px 8px rgba(146, 129, 255, 0.5);
  transition: 0.3s;
}

.radio {
  margin-right: 4px;
}

.txt {
  margin: 16px 0 0;
}

.note {
  color: #999;
  font-size: 12px;
  line-height: 140%;
  text-indent: -0.5rem;
  margin: 8px 0 0;
  padding-left: 0.5rem;
  list-style: none;
}

.note li {
  margin-top: 4px;
}

.note a {
  color: #2f80ed;
  text-decoration: underline;
}

.note a:hover {
  text-decoration: none;
}

.required {
  color: #eb5757;
  font-weight: normal;
}

.checkbox {
  display: flex;
  align-items: center;
  margin-top: 24px;
  box-sizing: border-box;
  margin: 16px 0;
  padding: 0;
  max-width: 640px;
}

.checkboxAgree {
  composes: checkbox;
  margin: 64px 0;
}

.agree {
  border: 1px solid #c4c4c4;
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.labelTerms {
  font-size: 14px;
  margin-left: 8px;
  margin-bottom: 0;
}

.labelTerms a {
  color: #2f80ed;
  text-decoration: underline;
}

.labelTerms a:hover {
  text-decoration: none;
}

.attachmentArea {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.attachmentArea .label {
  margin: 0;
}

.btnAttachment {
  font-size: 14px;
  color: #2f80ed;
  text-decoration: underline;
  border: none;
  background: none;
  cursor: pointer;
}

.btnAttachment:hover {
  text-decoration: none;
}

.attachment {
  list-style: none;
  padding: 0 0 0 1rem;
}

.file {
  font-size: 14px;
  line-height: 160%;
  margin-top: 8px;
}

.file:first-child {
  margin-top: 0;
}

.file a {
  color: #333;
}

.file a:hover {
  text-decoration: none;
}

.flexRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.reloadBtn {
  margin: 8px 0;
}

@media screen and (max-width: 768px) {
  .container {
    height: auto;
    padding-bottom: 160px;
  }

  .ttl {
    margin-top: 48px;
  }

  .box {
    width: 100%;
    margin-top: 32px;
    padding: 0 16px;
  }

  .selectArea,
  .inputPrice,
  .radioArea {
    width: 100%;
  }
}
