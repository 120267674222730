.button {
  background: #fff;
  border: 1px solid #c5c5c5;
  border-radius: 8px;
  color: #333;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  height: 40px;
  line-height: 20px;
  margin: 0;
  padding: 0 16px;
  box-sizing: border-box;
  user-select: none;
}

.button:disabled {
  background-color: #f0f0f0;
  border-color: #f0f0f0;
  color: #fff;
  cursor: unset;
}

@media (hover: hover) {
  .button:hover:not(:disabled) {
    background-color: #f0f0f0;
  }

  .button:active:not(:disabled) {
    opacity: 0.7;
  }
}

@media (hover: none) {
  .button:active:not(:disabled) {
    background-color: #f0f0f0;
  }
}
