.container {
  display: flex;
  justify-content: space-between;
  border-bottom: solid 1px #eaeaea;
  height: 64px;
  padding: 0 24px;
  position: fixed;
  width: 100%;
  box-sizing: border-box;
  top: 0;
  background: #fff;
  z-index: 9999;
}

.flex {
  display: flex;
  align-items: center;
}

.logo {
  font-size: 24px;
}

.logo a {
  color: #333;
  text-decoration: none;
}

.searchArea {
  position: relative;
  margin-left: 24px;
}

.searchIcon {
  position: absolute;
  top: 8px;
  left: 16px;
}

.search {
  background: #f3f3f3;
  border: solid 1px transparent;
  border-radius: 8px;
  color: #333;
  width: 240px;
  height: 40px;
  padding: 0 16px 0 48px;
  transition: 0.3s;
}

.search:hover {
  background: #fff;
  border: solid 1px #9281ff;
  box-shadow: 0px 0px 8px rgba(146, 129, 255, 0.5);
  transition: 0.3s;
}

.search:focus {
  outline: none;
  background: #fff;
  border: solid 1px #9281ff;
  box-shadow: 0px 0px 8px rgba(146, 129, 255, 0.5);
  transition: 0.3s;
}

.btn {
  background: #9281ff;
  border: none;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
  padding: 0 16px;
  transition: 0.5s;
}

a.btn {
  text-decoration: none;
  line-height: 20px;
  padding: 5px 16px;
}

button.btn {
  height: 40px;
}

.btn:hover {
  opacity: 0.7;
  transition: 0.5s;
}

.user {
  border: solid 1px #eaeaea;
  border-radius: 50px;
  cursor: pointer;
  margin-left: 16px;
  padding: 0;
  width: 30px;
  height: 30px;
  overflow: hidden;
  transition: 0.5s;
}

.user:hover {
  opacity: 0.7;
  transition: 0.5s;
}

.user img {
  width: 100%;
}

.userMenu {
  background: #fff;
  border-radius: 8px;
  border: 1px solid #eaeaea;
  width: 240px;
  position: absolute;
  top: 48px;
  right: 6px;
  box-shadow: 0px 0px 1.7px rgba(0, 0, 0, 0.04),
    0px 0px 5.6px rgba(0, 0, 0, 0.06), 0px 0px 25px rgba(0, 0, 0, 0.1);
  z-index: 10;
}

.userMenu::before {
  content: "";
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  right: 9px;
  top: -12px;
  border-right: 8px solid transparent;
  border-bottom: 12px solid #eaeaea;
  border-left: 8px solid transparent;
}

/* beforeで本体の三角を表現 */
.userMenu::after {
  content: "";
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  right: 9px;
  top: -9px;
  border-right: 8px solid transparent;
  border-bottom: 12px solid #fff;
  border-left: 8px solid transparent;
}

.userMenuContent {
  font-size: 14px;
  list-style: none;
  margin: 0 16px;
  padding: 16px 0 24px;
}

.userMenuContent li {
  margin-top: 8px;
}

.userMenuContent a {
  color: #333;
  text-decoration: none;
  transition: 0.3s;
}

.userMenuContent a:hover {
  color: #9281ff;
  transition: 0.3s;
}

.userMenuContentTop {
  composes: userMenuContent;
  border-bottom: 1px solid #eaeaea;
}

.userMenuContentBottom {
  composes: userMenuContent;
}

.spb {
  display: none;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 5;
}

@media screen and (max-width: 768px) {
  .container {
    display: none;
  }

  .spb {
    display: block;
  }
}
