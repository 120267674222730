.container {
  display: flex;
  flex-flow: column;
  min-height: 100vh;
}

.container main {
  flex: 1;
}

@media screen and (max-width: 768px) {
  .container {
    padding-top: 32px;
  }
}
