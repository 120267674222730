.container {
  background: #fff;
}

.ttl {
  border-bottom: 1px solid #eaeaea;
  font-size: 16px;
  margin: 0;
  padding: 16px;
  text-align: center;
}

.body {
  margin: 0;
  padding: 32px;
}

@media screen and (max-width: 768px) {
  .container {
    height: auto;
    padding-bottom: 64px;
  }

  .body {
    margin: 32px 0 0;
    padding: 0 16px;
  }
}
