.header {
  font-weight: 700;
  font-size: 18px;
  line-height: 160%;
  text-align: center;

  margin: 0;
  margin-bottom: 32px;
}

.text {
  font-size: 18px;
  line-height: 160%;
  margin-bottom: 24px;
}

.list {
  font-size: 18px;
  line-height: 160%;

  list-style: none;
  padding: 0;
}
