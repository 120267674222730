.container {
  box-sizing: border-box;
  width: 960px;
  margin: 0 auto;
}

.total {
  border-bottom: 1px solid #f1f1f1;
  margin-top: 32px;
  padding-bottom: 24px;
}

.hover {
  cursor: pointer;
}

.hover:hover {
  background-color: #f1f1f1;
}

.total small {
  color: #999;
  font-size: 12px;
}

.total dl {
  margin: 8px 0 0;
}

.totalSales {
  font-size: 12px;
  margin: 8px 0 0;
}

.totalPrice {
  font-size: 32px;
  margin: 4px 0 0;
}

.monthly {
  margin-top: 24px;
}

.label {
  font-size: 12px;
  margin: 16px 0 0;
}

.monthlySales {
  font-size: 24px;
  margin-top: 8px;
}

.monthlyDeposit {
  font-size: 16px;
  margin: 8px 0 0;
}

.note {
  color: #eb5757;
  font-size: 12px;
  margin-top: 8px;
}

.table {
  display: table;
  width: 100%;
  border-collapse: collapse;
  margin-top: 64px;
}

.thead th {
  border-bottom: 1px solid #333;
  box-sizing: border-box;
  font-size: 10px;
  padding: 8 16px;
}

.theadMonth,
.theadStatus,
.theadConfirm,
.theadDeposit {
  text-align: left;
}

.theadYen,
.theadProfit {
  text-align: right;
}

.theadYen span {
  white-space: nowrap;
}

.tdataStatus,
.tdataConfirm,
.tdataDeposit,
.tdataYen,
.tdataProfit {
  border-bottom: 1px solid #eaeaea;
  box-sizing: border-box;
  font-size: 14px;
  padding: 24px 0;
}

.box:last-child td {
  border-bottom: none;
}

.tdataStatus,
.tdataConfirm,
.tdataDeposit,
.tdataYen,
.tdataProfit {
  border-bottom: 1px solid #eaeaea;
}

.tdataMonth,
.tdataStatus,
.tdataConfirm {
  padding-right: 16px;
  box-sizing: border-box;
}

.tdataMonth,
.tdataStatus span,
.tdataConfirm {
  white-space: nowrap;
}

.tdataYen,
.tdataProfit {
  padding-left: 16px;
  box-sizing: border-box;
  text-align: right;
  font-weight: bold;
}

.tdataStatus span {
  background: #fff;
  border: 1px solid #eb5757;
  border-radius: 4px;
  color: #eb5757;
  font-size: 12px;
  font-weight: bold;
  padding: 4px;
}

.tdataStatus[data-status="pending"] span {
  background: #fff;
  border: 1px solid #c4c4c4;
  color: #333;
}

.tdataStatus[data-status="paid"] span {
  background: #fff;
  border: 1px solid #c4c4c4;
  color: #999;
}

.tdataStatus[data-status="failed"] span {
  background: #eb5757;
  border: 1px solid #eb5757;
  color: #fff;
}

.tdataStatus[data-status="stop"] span {
  background: #fff;
  border: 1px solid #eb5757;
  color: #eb5757;
}

.tdataStatus[data-status="carried_over"] span {
  background: #f3f3f3;
  border: none;
  color: #333;
}

.tdataStatus[data-status="recombination"] span {
  background: #eb5757;
  border: 1px solid #eb5757;
  color: #fff;
}

@media screen and (max-width: 960px) {
  .container {
    width: 100%;
    padding: 0 64px;
  }
}

@media screen and (max-width: 768px) {
  .container {
    padding: 0;
  }

  .total {
    box-sizing: border-box;
    margin: 32px 16px 24px;
    padding-bottom: 24px;
  }

  .monthly {
    box-sizing: border-box;
    padding: 0 16px;
  }

  .monthlyDeposit {
    font-size: 14px;
  }

  .table {
    width: 100%;
    margin-top: 48px;
  }

  .thead {
    display: none;
  }

  .box {
    width: 100%;
    display: block;
    padding: 0 16px;
    box-sizing: border-box;
    border-top: 1px solid #eaeaea;
  }

  .box:last-child {
    border-bottom: 1px solid #eaeaea;
  }

  .box:last-child td {
    border-bottom: 1px dashed #eaeaea;
  }

  .tdataMonth {
    display: block;
    font-size: 16px;
    text-align: left;
    margin-top: 24px;
    border: none !important;
  }

  .tdataStatus,
  .tdataConfirm,
  .tdataDeposit,
  .tdataYen,
  .tdataProfit {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 0;
    border-bottom: 1px dashed #eaeaea;
    font-size: 12px;
  }

  .tdataProfit {
    border: none;
    padding-bottom: 24px;
  }

  .tdataStatus:before,
  .tdataConfirm:before,
  .tdataDeposit:before,
  .tdataYen:before,
  .tdataProfit:before {
    content: attr(data-label);
    color: #c4c4c4;
    font-size: 10px;
    font-weight: bold;
  }

  .txt {
    padding: 0 16px;
  }
}
