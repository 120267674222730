.Settings__heading {
  margin: 0;
  font-size: 24px;
  font-weight: bold;
}

.Settings__section {
  margin: 16px 0;
  padding: 24px 0 16px 0;
  border-top: 1px solid #e0e0e0;
}

.Settings__section:last-child {
  margin-bottom: 0;
}

.Settings__sectionHeading {
  margin: 0;
  font-size: 16px;
  font-weight: bold;
}

.Settings__combineForm {
  display: flex;
  column-gap: 4px;
  margin-top: 8px;
}

.Settings__input {
  font-size: 14px;
  line-height: 30px;
  border: solid 1px #c4c4c4;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 4px 16px;
  flex-grow: 1;
}

.Settings__input:hover {
  background: #fff;
  border: solid 1px #9281ff;
  box-shadow: 0px 0px 8px rgba(146, 129, 255, 0.5);
  transition: 0.3s;
}

.Settings__input:focus {
  outline: none;
  background: #fff;
  border: solid 1px #9281ff;
  box-shadow: 0px 0px 8px rgba(146, 129, 255, 0.5);
  transition: 0.3s;
}

.Settings__buttonSave {
  background: #9281ff;
  border: 1px solid #9281ff;
  border-radius: 8px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  line-height: 2;
  font-weight: bold;
  padding: 0 16px;
  min-width: 4rem;
}

.Settings__buttonSave:hover {
  opacity: 0.8;
}

.Settings__buttonSave:active {
  opacity: 0.3;
}

.Settings__buttonSection {
  display: flex;
  column-gap: 4px;
  margin-top: 8px;
  height: 40px;

  align-items: center;
}

.Settings__buttonSection_note {
  font-size: 12px;
  color: #333;
  line-height: 1.6;
  margin: 0;
  flex-grow: 1;
}

.Settings__buttonDelete {
  background: #fff;
  border: 1px solid #c4c4c4;
  border-radius: 8px;
  box-sizing: border-box;
  color: #eb5757;
  cursor: pointer;
  font-size: 14px;
  line-height: 2;
  font-weight: bold;
  padding: 0 16px;

  white-space: nowrap;
  flex-shrink: 0;

  height: 40px;
}

.Settings__buttonDelete:hover {
  background: #eb5757;
  border: 1px solid #eb5757;
  color: #fff;
  opacity: 1;
}

.Settings__buttonDelete:active {
  opacity: 0.3;
}
