.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 20001;
}

.bg {
  background: #fff;
  width: 640px;
  margin: 0 auto;
  position: relative;
  animation-name: fadeIn;
  animation-fill-mode: forwards;
  animation-duration: 0.7s;
  animation-iteration-count: 1;
  animation-timing-function: easeOutQuart;
  overflow-y: scroll;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.bg::-webkit-scrollbar {
  display: none;
}

.overlay {
  background: rgba(0, 0, 0, 0.7);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20000;
}

@media screen and (max-width: 768px) {
  .modal {
    width: 100%;
    padding: 0 16px;
    box-sizing: border-box;
  }

  .bg {
    width: 100%;
    min-height: auto;
  }
}
