@keyframes spin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(359deg);
  }
}

.spinner {
  width: 50px;
  height: 50px;
  border: 3px solid #777;
  border-top: 3px solid transparent;
  border-radius: 50%;
  -webkit-animation: spin 1s linear 0s infinite;
  animation: spin 1s linear 0s infinite;
}

.spinnerFull {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.spinnerCenter {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
