.container {
  box-sizing: border-box;
  margin-top: 32px;
}

@media screen and (max-width: 768px) {
  .container {
    box-sizing: border-box;
    margin-top: 0;
  }
}
