.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 20001;
}

.bg {
  background: #fff;
  width: 640px;
  margin: 0 auto;
  position: relative;
  animation-name: fadeIn;
  animation-fill-mode: forwards;
  animation-duration: 0.7s;
  animation-iteration-count: 1;
  animation-timing-function: easeOutQuart;
  overflow-y: scroll;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.bg::-webkit-scrollbar {
  display: none;
}

.close {
  color: #333;
  display: flex;
  align-items: center;
  position: absolute;
  top: 12px;
  right: 16px;
  cursor: pointer;
  font-weight: bold;
  font-size: 12px;
  transition: 0.5s;
  z-index: 99;
}

.close:hover {
  opacity: 0.7;
  transition: 0.5s;
}

.close svg {
  fill: #333;
  margin-left: 4px;
}

.ttl {
  border-bottom: 1px solid #eaeaea;
  font-size: 16px;
  margin: 0;
  padding: 16px;
  text-align: center;
}

/* .overflowScroll {
  max-height: calc(90vh - 50px);
  overflow-y: scroll;
} */

.overlay {
  background: rgba(0, 0, 0, 0.7);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20000;
}

@media screen and (max-width: 768px) {
  .modal {
    width: 100%;
    padding: 0 16px;
    box-sizing: border-box;
  }

  .bg {
    width: 100%;
    min-height: auto;
  }

  .close {
    right: 24px;
  }

  .close span {
    display: none;
  }

  /* .overflowScroll {
    max-height: calc(100vh - 50px);
  } */
}
