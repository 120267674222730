.container {
  box-sizing: border-box;
  width: 480px;
  margin: 0 auto;
}

.note {
  background: #f8f8f8;
  border-radius: 8px;
  box-sizing: border-box;
  font-size: 12px;
  margin-top: 32px;
  padding: 24px;
  line-height: 140%;
  color: #eb5757;
  text-indent: -0.5rem;
}

.txt {
  font-size: 16px;
  margin: 24px 0 0;
  line-height: 160%;
}

.coments {
  color: #999;
  font-size: 12px;
  margin: 8px 0 0;
  line-height: 140%;
}

.border {
  display: flex;
  align-items: center;
  color: #999;
  font-size: 14px;
  margin: 48px 0 0;
}

.border:before {
  content: "";
  height: 1px;
  flex-grow: 1;
  background-color: #eaeaea;
  margin-right: 1rem;
}

.border:after {
  content: "";
  height: 1px;
  flex-grow: 1;
  background-color: #eaeaea;
  margin-left: 1rem;
}

.btn {
  background: #9281ff;
  border: none;
  border-radius: 8px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  height: 40px;
  margin: 16px auto 0;
  padding: 0 16px;
  transition: 0.3s;
  text-decoration: none;
}

.btn svg {
  margin-right: 4px;
}

.btn svg {
  fill: #9281ff;
}

.btn:hover {
  opacity: 0.8;
  transition: 0.3s;
}

.btn:active {
  opacity: 0.3;
}

.btn:hover svg {
  fill: #fff;
  transition: 0.3s;
}

@media screen and (max-width: 768px) {
  .container {
    width: 100%;
    padding: 0 16px;
  }

  .btn {
    margin: 16px auto 0;
  }
}
