.container {
  background: #fff;
  box-sizing: border-box;
  padding-bottom: 80px;
}

.inner {
  box-sizing: border-box;
  margin: 64px auto;
  padding: 0;
  width: 640px;
}

.head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 64px 0 32px;
}

.ttl {
  font-size: 18px;
  margin: 0;
  padding: 0;
  text-align: center;
}

.btn {
  background: #9281ff;
  border: 1px solid #9281ff;
  border-radius: 8px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  height: 40px;
  margin: 0;
  padding: 0 16px;
  transition: 0.3s;
}

.btn svg {
  margin-right: 4px;
}

.btn svg {
  fill: #9281ff;
}

.btn:hover {
  opacity: 0.8;
  transition: 0.3s;
}

.btn:active {
  opacity: 0.3;
}

.btn:hover svg {
  fill: #fff;
  transition: 0.3s;
}

.thead {
  list-style: none;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #333;
  box-sizing: border-box;
  font-size: 12px;
  font-weight: bold;
  margin-top: 32px;
  padding: 0 16px 8px;
}

.theadItem {
  width: 60%;
  margin-right: 24px;
}

.theadPublish {
  width: 12%;
  margin-right: 24px;
}

.theadRange {
  width: 20%;
}

.list {
  list-style: none;
  padding: 0;
}

.list a {
  text-decoration: none;
  padding: 16px;
  display: block;
}

.list li {
  box-sizing: border-box;
  border-bottom: 1px solid #f1f1f1;
}

.list li:hover {
  background: rgba(146, 129, 255, 0.3);
  transition: 0.3s;
}

.box {
  display: flex;
  align-items: center;
  width: 100%;
}

.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  width: 60%;
  margin-right: 24px;
}

.txt {
  box-sizing: border-box;
  margin-right: 16px;
  width: 100%;
}

.genre {
  color: #9281ff;
  font-size: 12px;
  margin: 0;
  line-height: 160%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.name {
  color: #333;
  font-size: 14px;
  font-weight: normal;
  margin: 0;
  line-height: 160%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.thumnail {
  width: 120px;
  border-radius: 4px;
  border: 1px solid #c4c4c4;
  overflow: hidden;
}

.img {
  width: 100%;
  padding-top: 56.25%;
  position: relative;
  overflow: hidden;
}

.img img {
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
}

.publish,
.range {
  color: #333;
  font-size: 12px;
}

.publish {
  width: 12%;
  margin-right: 24px;
}

.publish[data-checked="true"] {
  color: #eb5757;
}

.range {
  width: 20%;
}

.loading {
  margin-top: 64px;
}

@media screen and (max-width: 768px) {
  .container {
    height: auto;
    padding-bottom: 48px;
  }

  .inner {
    width: 100%;
    margin: 48px 0 0;
  }

  .head {
    display: block;
    margin: 48px 0 24px;
    text-align: center;
  }

  .btn {
    margin: 24px auto 0;
  }

  .thead {
    display: none;
  }

  .list {
    border-top: 1px solid #f1f1f1;
  }

  .box {
    position: relative;
    display: block;
  }

  .item {
    width: calc(100% - 80px);
  }

  .txt {
    width: 100%;
  }

  .genre {
    font-size: 10px;
  }

  .name {
    font-size: 12px;
    line-height: 140%;
    margin-top: 4px;
  }

  .thumnail {
    width: 80px;
    position: absolute;
    top: 50%;
    right: 0%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
  }

  .publish,
  .range {
    width: auto;
    font-size: 10px;
    display: inline-block;
  }

  .publish {
    margin-right: 0;
  }

  .publish:after {
    content: ",";
    color: #333;
    display: inline-block;
    margin: 0 2px;
  }
}
