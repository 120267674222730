.container {
  padding-top: 64px;
}

.profile {
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 48px;
}

.inner {
  width: 480px;
  margin: 64px auto 0;
  text-align: center;
}

.img {
  width: 160px;
  height: 160px;
  overflow: hidden;
  border-radius: 50%;
  border: 1px solid #c4c4c4;
  margin: 0 auto;
  text-align: center;
}

.img img {
  width: 100%;
  min-height: 160px;
}

.name {
  font-size: 24px;
  margin: 24px 0 0;
  word-break: break-word;
  line-height: 160%;
}

.description {
  font-size: 14px;
  line-height: 160%;
  margin: 16px 0 0;
  white-space: pre-wrap;
}

.btn {
  background: #fff;
  border: 1px solid #c4c4c4;
  border-radius: 50px;
  color: #9281ff;
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
  height: 40px;
  margin-top: 24px;
  padding: 2px 16px 0;
  transition: 0.3s;
}

.btn:hover {
  background: #9281ff;
  border: 1px solid #9281ff;
  color: #fff;
  transition: 0.3s;
}

.btn:active {
  opacity: 0.3;
}

.btn svg {
  fill: #9281ff;
  margin-right: 4px;
  vertical-align: middle;
  transition: 0.3s;
}

.btn:hover svg {
  fill: #fff;
  transition: 0.3s;
}

.bottom {
  text-align: center;
}

.btnTerms {
  composes: btn;
  border-radius: 4px;
  border: 1px solid #c4c4c4;
  color: #333;
  font-weight: normal;
  margin: 0;
  padding: 4px 8px;
  height: auto;
}

.btnTerms:hover {
  background: #fff;
  border: 1px solid #9281ff;
  color: #9281ff;
}

@media screen and (max-width: 768px) {
  .container {
    padding-top: 40px;
  }

  .profile {
    padding-bottom: 32px;
  }

  .inner {
    width: 100%;
    margin: 32px auto 0;
    padding: 0 16px;
    box-sizing: border-box;
  }

  .img {
    width: 120px;
    height: 120px;
  }

  .img img {
    min-height: 120px;
  }

  .name {
    font-size: 18px;
  }
}
