.button {
  background: #fff;
  border: 1px solid #9281ff;
  color: #9281ff;
  border-radius: 8px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  line-height: 38px;
  font-weight: bold;
  width: 100%;
  height: 40px;
  margin: 8px 0 0;
  padding: 0 16px;
  transition: opacity 0.4s cubic-bezier(0.22, 1, 0.36, 1);
}

.button:disabled {
  background: #f2f2f2;
  border: none;
  color: #c4c4c4;
  cursor: unset;
}

.button svg {
  fill: #9281ff;
  margin-right: 4px;
}

.button:disabled svg {
  fill: #bdbdbd;
}

@media (hover: hover) {
  .button:not(:disabled):hover {
    opacity: 0.5;
  }
  .button:not(:disabled):active {
    opacity: 0.2;
  }
}

.link {
  text-decoration: none;
}
