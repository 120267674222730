.container {
  box-sizing: border-box;
  margin: 32px 0 0;
}

.txt {
  font-size: 12px;
  text-align: center;
  margin: 0;
}

.list {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  box-sizing: border-box;
  margin: 8px auto 0;
  padding: 0;
  list-style: none;
}

.list li {
  margin-left: 16px;
}

.list li:first-child {
  margin-left: 0;
}

.btn a {
  text-decoration: none;
}

.btn:first-child {
  margin-left: 0;
}

@media screen and (max-width: 768px) {
  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 16px 0 0;
    padding: 16px 16px 80px;
    border-top: 1px solid #eaeaea;
  }

  .list {
    align-items: center;
    margin: 0;
  }

  .list li {
    margin-top: 0;
  }

  .btn:first-child {
    margin-top: 0;
  }
}
