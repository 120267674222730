.container {
  padding-top: 64px;
}

.section {
  box-sizing: border-box;
  margin: 64px auto 0;
  width: 680px;
}

.ttl {
  font-size: 18px;
  text-align: center;
  margin-bottom: 32px;
}

.list {
  padding: 0;
}

.item {
  list-style: none;
  padding: 32px 0;
  display: flex;
  border-bottom: 1px solid #eaeaea;
}

.item:last-child {
  border: none;
}

.thumnail {
  width: 120px;
  padding-top: 56.25%;
  position: relative;
  overflow: hidden;
}

.thumnail img {
  width: 120px;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
}

.right {
  margin-left: 24px;
  width: 100%;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.left {
  margin-top: 0;
}

.label {
  margin: 0;
  padding: 4px;
  background: #fff;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  color: #999;
  font-size: 14px;
  font-weight: 10px;
  margin-right: 8px;
}

.label[data-checked="true"] {
  background: #eb5757;
  border: 1px solid #eb5757;
  color: #fff;
}

.price {
  font-size: 16px;
  margin: 0;
}

.genre {
  color: #9281ff;
  font-size: 12px;
  margin: 16px 0 0;
}

.name {
  font-size: 16px;
  font-weight: normal;
  margin: 8px 0 0;
  line-height: 160%;
}

.number {
  font-size: 14px;
  font-weight: bold;
  margin: 8px 0 0;
}

.gift {
  font-size: 14px;
  font-weight: bold;
  margin: 16px 0 0;
}

.gift span {
  color: #eb5757;
}

.url {
  color: #999;
  cursor: pointer;
  text-decoration: underline;
  font-size: 14px;
  margin: 8px 0 0;
  display: flex;
  align-items: center;
  word-break: break-all;
  background: none;
  border: none;
  transition: 0.4s cubic-bezier(0.22, 1, 0.36, 1);
  padding: 0;
}

.url svg {
  fill: #999;
  margin-right: 4px;
  transition: 0.4s cubic-bezier(0.22, 1, 0.36, 1);
}

@media (hover: hover) {
  .url:hover {
    color: #333;
    transition: 0.4s cubic-bezier(0.22, 1, 0.36, 1);
  }
  .url:active {
    opacity: 0.5;
    transition: 0.4s cubic-bezier(0.22, 1, 0.36, 1);
  }
  .url:hover svg {
    fill: #333;
    transition: 0.4s cubic-bezier(0.22, 1, 0.36, 1);
  }
}

.status {
  margin-top: 16px;
  padding: 0;
  list-style: none;
  color: #999;
  font-size: 12px;
}

.status li {
  margin-top: 8px;
}

.cancel {
  margin: 16px 0 0;
}

.cancel a {
  color: #2f80ed;
  font-size: 14px;
}

@media screen and (max-width: 768px) {
  .container {
    padding-top: 32px;
  }

  .section {
    width: 100%;
  }

  .item {
    box-sizing: border-box;
    padding: 24px 16px;
  }

  .thumnail {
    width: 80px;
  }

  .thumnail img {
    width: 80px;
  }

  .right {
    margin-left: 16px;
  }

  .name {
    font-size: 14px;
    line-height: 140%;
  }

  .url {
    text-align: left;
    align-items: flex-start;
  }
}
