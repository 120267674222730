.container {
  margin: 40px auto 0;
  box-sizing: border-box;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .container {
    padding: 0 16px;
  }
}

.ttl {
  font-size: 16px;
  border-bottom: 1px solid #eaeaea;
  margin: 0;
  padding-bottom: 4px;
}

.booklet,
.attachment {
  font-size: 14px;
  font-weight: bold;
  margin: 16px 0 0;
}

.attachment {
  margin-top: 24px;
}

.list {
  list-style: none;
  margin: 8px 0 0;
  padding: 0 1rem;
}

.list li {
  margin-top: 8px;
}

.list a {
  color: #555;
  text-decoration: none;
  font-size: 14px;
  word-break: break-all;
}

@media (hover: hover) {
  .list a:hover {
    color: #000;
    text-decoration: underline;
  }
}

.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex * {
  margin: 0;
}

.name,
.fileName {
  font-size: 14px;
  line-height: 20px;
}

.count {
  color: #999;
  font-size: 14px;
}

.note {
  color: #999;
  font-size: 12px;
  line-height: 140%;
  margin: 40px 0 0;
}

.note p {
  margin: 8px 0 0;
  white-space: pre-wrap;
}
