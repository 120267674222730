.container {
  width: 960px;
  margin: 40px auto 0;
  box-sizing: border-box;
}

@media screen and (max-width: 768px) {
  .container {
    width: 100%;
    margin: 32px auto 0;
    box-sizing: border-box;
  }
}
