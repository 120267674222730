.Preview__buttonClose {
  position: fixed;
  top: 8px;
  right: 8px;
  z-index: 10000;

  background: #f0f0f0;
  border: 1px solid #f0f0f0;
  border-radius: 8px;
  color: #333;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  height: 40px;
  margin: 0;
  padding: 2px 16px 0;
  box-sizing: border-box;
  user-select: none;
}

.Preview__buttonClose:hover {
  background: #e0e0e0;
}

.Preview__buttonClose:active {
  opacity: 0.3;
}

body.EditorLexical.Preview {
  background-color: #fff;
}
