.floating {
  display: none;
}

.button {
  width: 100%;
  height: 50px;
  color: #333;
  font-weight: bold;
  background-color: #fff;
  border: none;
  border-top: 1px solid #bdbdbd;
  border-radius: 0;
  cursor: pointer;
}

.button:hover {
  opacity: 0.5;
}

.button:active {
  opacity: 0.5;
}

.link {
  text-decoration: none;
}

@media screen and (max-width: 768px) {
  .floating {
    display: block;
    position: fixed;
    bottom: 0;
    width: 100%;
  }
}
