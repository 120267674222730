.img {
  margin: 24px 0 0;
  padding-top: calc(56.25% - 2px);
  position: relative;
  overflow: hidden;
  border: 1px solid #eaeaea;
}

.imgBlank {
  composes: img;
  background: #f1f1f1;
  position: relative;
}

.imgBlank p {
  box-sizing: border-box;
  color: #c4c4c4;
  font-size: 48px;
  font-family: "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "游明朝",
    "Yu Mincho", "游明朝体", "YuMincho", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  margin: 0;
  padding: 24px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  word-break: break-word;
  width: 100%;
  text-align: center;
}
