.Editor__root {
  margin: 0 240px;
  padding: 80px;

  background-color: #f5f5f5;
}

.Editor__container {
  box-sizing: border-box;
  position: relative;
  margin: 0 auto;
  width: calc(700px + 80px);
}

.Editor__placeholder {
  position: absolute;
  top: calc(84px + 32px + 24px);
  left: 40px;

  color: #999;
  user-select: none;
  pointer-events: none;
}

@media screen and (max-width: 1440px) {
  .Editor__root {
    margin: 0;
  }

  .Editor__root[data-open-sidebar="true"] {
    margin-left: 240px;
  }
}

@media screen and (max-width: 1120px) {
  .Editor__root,
  .Editor__root[data-open-sidebar="true"] {
    margin: 0;
    padding: 80px 16px;
  }

  .Editor__container {
    width: auto;
    max-width: 800px;
  }
}

@media screen and (max-width: 900px) {
  .Editor__root,
  .Editor__root[data-open-sidebar="true"] {
    padding: 120px 16px 80px 16px;
  }
}

@media screen and (max-width: 414px) {
  .Editor__placeholder {
    top: calc(40px + 32px + 24px);
    left: 16px;
  }
}

body.EditorLexical {
  background-color: #f5f5f5;
}

.Editor__InputBookletName_root {
  background-color: transparent;
  border: none;
  outline: none;

  display: block;
  box-sizing: border-box;
  width: 100%;
  max-width: 800px;
  padding: 0 8px;
  margin: 0 auto;

  font-size: 48px;
  line-height: 140%;
  margin-bottom: 24px;
}

.Editor__InputBookletName_root::placeholder {
  color: #999;
}

@media screen and (max-width: 1400px) {
  .Editor__InputBookletName_root {
    margin-left: 0;
    margin-right: 0;
  }
}

@media screen and (max-width: 414px) {
  .Editor__InputBookletName_root {
    font-size: 32px;
    line-height: 130%;
  }
}
