.container {
  background: #fff;
}

.ttl {
  border-bottom: 1px solid #eaeaea;
  font-size: 16px;
  margin: 0;
  padding: 16px;
  text-align: center;
}

.box {
  box-sizing: border-box;
  padding: 24px;
}

.label {
  background: #fff;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  color: #999;
  display: inline-block;
  font-size: 12px;
  margin: 0;
  padding: 4px 8px;
}

.label[data-unpaid="true"] {
  background: #eb5757;
  border: none;
  color: #fff;
}

.name {
  font-size: 16px;
  font-weight: normal;
  margin: 8px 0 0;
}

.list {
  list-style: none;
  margin: 24px 0 0;
  padding: 0;
}

.inner {
  display: flex;
  align-items: top;
  margin-top: 24px;
}

.status {
  color: #c4c4c4;
  font-size: 12px;
  width: 15%;
  margin: 0;
  flex-shrink: 0;
}

.txt {
  font-size: 14px;
  margin: 0;
}

.txtPrice {
  composes: txt;
  font-size: 16px;
  border-bottom: 1px dashed #eaeaea;
  padding-bottom: 8px;
}

.breakdown {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  font-size: 14px;
}

.note {
  color: #c4c4c4;
  font-size: 12px;
  margin-top: 24px;
  padding: 0 0 0 0.5rem;
  line-height: 140%;
  list-style: none;
  text-indent: -0.5rem;
}

@media screen and (max-width: 768px) {
  .container {
    height: auto;
    padding-bottom: 64px;
  }

  .box {
    box-sizing: border-box;
    padding: 16px;
  }

  .inner {
    margin-top: 16px;
  }

  .status {
    width: 24%;
  }

  .right {
    width: 76%;
  }

  .breakdown {
    margin-top: 8px;
  }
}
