.container {
  box-sizing: border-box;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  flex-grow: 1;
  padding: 8px 8px 40px 8px;
}

.container::-webkit-scrollbar {
  display: none;
}

.sectionAdd {
  margin: 48px 8px 0;
  width: 100%;
  box-sizing: border-box;
}

.ttl {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #999;
  cursor: pointer;
  font-size: 14px;
  font-weight: normal;
  height: 24px;
  margin: 8px 0 0 4px;
  padding: 0;
  position: relative;
}

.ttl:hover {
  color: #333;
  font-weight: bold;
}

.arrow {
  width: 24px;
  height: 24px;
  position: relative;
  z-index: 100;
  cursor: pointer;
  flex-shrink: 0;
  flex-grow: 0;
}

.arrow[data-open="false"] {
  transform: rotate(-90deg);
}

.txt {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  width: 100%;
  height: 1rem;
}

.hover {
  display: none;
}

.ttl:hover .hover {
  display: flex;
  height: 24px;
}

.delete,
.up,
.down {
  position: relative;
  z-index: 99;
}

.delete svg,
.up svg,
.down svg {
  fill: #999;
  width: 24px;
}

.delete:hover svg,
.up:hover svg,
.down:hover svg {
  fill: #9281ff;
  stroke: #9281ff;
}

.ttlActive {
  composes: ttl;
  color: #333;
  font-weight: bold;
}

.ttlActive .txt svg {
  stroke: #333;
}

.list {
  color: #999;
  font-size: 14px;
  list-style: none;
  margin: 0 0 24px 28px;
  padding: 0;
}

.list li {
  cursor: pointer;
  margin-top: 8px;
  position: relative;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  line-height: 24px;
}

.list li.additonalPadding {
  padding-left: 1em;
}

.list li:hover {
  color: #333;
}

.btnAdd {
  background: none;
  border: 0;
  color: #333;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0;
  width: 100%;
}

.btnAdd:hover {
  font-weight: bold;
}

.btnAdd svg {
  margin-right: 2px;
}

.noHeaders {
  font-size: 14px;
  color: #333;
  user-select: none;
  margin: 8px 0 0 28px;
  line-height: 24px;
}

.activeBooklet {
  color: #9281ff;
  font-size: 14px;
  font-weight: bold;
  margin: 12px 0 16px 8px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

@media screen and (max-width: 768px) {
  .sectionAdd {
    margin-top: 32px;
  }

  .txt {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    width: calc(100% - 72px);
  }

  .hover {
    display: flex;
    height: 24px;
  }

  .ttl:hover::before {
    right: -28px;
  }

  .ttl:hover::after {
    right: -48px;
  }
}
