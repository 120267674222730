.container {
  display: none;
}

.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 8px;
  height: 40px;
}

.logo {
  font-size: 16px;
  text-align: center;
  margin: 0;
}

.logo a {
  color: #333;
  text-decoration: none;
  display: flex;
}

.logo svg {
  height: 24px;
}

@media screen and (max-width: 768px) {
  .container {
    display: block;
    background: #fff;
    border-bottom: 1px solid #eaeaea;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 100%;
  }
}
