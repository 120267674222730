.item {
  cursor: unset !important;
}

.skeleton {
  position: relative;
  overflow: hidden;
  background: #f1f1f1;
}

.skeleton::before {
  content: "";
  display: block;
  height: 100%;
  width: 100%;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.5),
    transparent
  );
  position: absolute;
  top: 0;
  left: 0;
  animation: skeleton-animation 1.2s linear infinite;
}

@keyframes skeleton-animation {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

.thumbnail {
  composes: skeleton;
  width: 100%;
  padding-top: calc(56.25% - 2px);
  border: solid 1px #fff;
  border-radius: 8px;
  box-sizing: border-box;
}

.ttl {
  composes: skeleton;
  width: 90%;
  height: 14px;
  margin: 10px 0 0;
  background-color: #f1f1f1;
}

.genre {
  composes: skeleton;
  width: 40%;
  height: 12px;
  margin: 8px 0 0px;
  background-color: #f1f1f1;
}

.name {
  composes: skeleton;
  width: 70%;
  height: 12px;
  margin: 8px 0 0px;
  background-color: #f1f1f1;
}
