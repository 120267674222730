.container {
  background: #fff;
  border-right: 1px solid #eaeaea;
  box-sizing: border-box;
  width: 240px;
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 9999;
  overflow: hidden;

  display: flex;
  flex-direction: column;
}

.ttl {
  color: #9281ff;
  font-size: 17px;
  margin: 0;
  line-height: 160%;
  cursor: pointer;
  position: relative;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  padding: 24px 16px 0 16px;
  word-break: break-all;

  flex-shrink: 0;
}

.ttl:hover {
  text-decoration: underline;
}

.ttl:after {
  content: url(./IconGear.svg);
  display: inline-block;
  background: #fff;
  background-size: cover;
  width: 20px;
  height: 20px;
  position: absolute;
  bottom: 0.2rem;
  right: 16px;
}

.sidebarHeader {
  margin: 24px 16px 0;
  padding: 0 0 16px;
  border-bottom: 1px solid #eaeaea;
  font-weight: bold;
}

.active {
  color: #333;
  font-weight: bold;
}

.overlay {
  opacity: 0;
}

.arrow {
  margin: 0 8px;
  width: 1rem;
}

.list li.arrow:hover {
  color: #999;
  font-weight: normal;
}

.footer {
  flex-shrink: 0;
  padding: 16px 0;
  margin: 0 16px;

  display: flex;
  flex-direction: column;
  align-items: center;

  border-top: 1px solid #eaeaea;
}

.footerNote {
  margin: 0 0 16px 0;
  font-size: 14px;
  line-height: 20px;
  color: #333;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .container {
    width: 80%;
    position: fixed;
    padding: 92px 0 49px;
    left: -100%;
    z-index: 998;
  }

  .container[data-open="false"] {
    left: -100%;
    transition: 0.3s;
  }

  .container[data-open="true"] {
    display: fixed;
    left: 0%;
    transition: 0.3s;
  }

  .overlay[data-open="false"] {
    animation-name: fadeOut;
    animation-fill-mode: forwards;
    animation-duration: 0.3s;
    animation-iteration-count: 1;
    animation-timing-function: ease;
    visibility: hidden;
  }

  @keyframes fadeOut {
    0% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }

  .overlay[data-open="true"] {
    display: block;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.8);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 997;
    animation-name: fadeIn;
    animation-fill-mode: forwards;
    animation-duration: 0.3s;
    animation-iteration-count: 1;
    animation-timing-function: ease;
    visibility: visible;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  .ttl:after {
    right: 24px;
  }
}
