.Toolbar__root {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9000;

  background-color: #fff;
  border-bottom: 1px solid #e0e0e0;
}

.Toolbar__menuButton {
  position: absolute;
  top: 0;
  left: 0;

  padding: 8px;

  cursor: pointer;
  fill: #999;
}

@media (hover: hover) {
  .Toolbar__menuButton:hover {
    background-color: #f5f5f5;
    fill: #333;
  }
}

@media (hover: none) {
  .Toolbar__menuButton:active {
    background-color: #f5f5f5;
    fill: #333;
  }
}

.Toolbar__userButton {
  position: absolute;
  top: 0;
  right: 8px;

  padding: 4px;
}

.Toolbar__container {
  max-width: calc(700px + 80px);
  height: 40px;
  margin: 0 auto;
  border-left: 1px solid #e0e0e0;
  box-sizing: border-box;
  padding-right: 20px;

  display: flex;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.Toolbar__container::-webkit-scrollbar {
  display: none;
}

@media (hover: none) {
  .Toolbar__container {
    overflow-x: auto;
    overflow-y: hidden;
  }
}

.Toolbar__button {
  width: 40px;
  height: 40px;
  padding: 8px;
  box-sizing: border-box;
  cursor: pointer;
  user-select: none;

  fill: #777;
}

.Toolbar__button[data-disabled="true"] {
  cursor: unset;
  fill: #ddd;
}

.Toolbar__button[data-active="true"] {
  fill: #111;
  background-color: #eef;
}

@media (hover: hover) {
  .Toolbar__button:hover {
    background-color: #eee;
  }

  .Toolbar__button[data-disabled="true"]:hover {
    background-color: unset;
  }
}

@media (hover: none) {
  .Toolbar__button:active {
    background-color: #eee;
  }
}

.Toolbar__buttonGroup {
  border-right: 1px solid #e0e0e0;
  flex-shrink: 0;
}

@media screen and (max-width: 1440px) {
  .Toolbar__root[data-open-sidebar="true"] {
    padding-left: 240px;
  }
}

@media screen and (max-width: 1200px) {
  .Toolbar__root[data-open-sidebar="true"] {
    padding-left: 320px;
  }

  .Toolbar__root[data-open-sidebar="true"] .Toolbar__container {
    margin: 0;
  }

  .Toolbar__container {
    margin: 0 auto;
  }
}

@media screen and (max-width: 1120px) {
  .Toolbar__root[data-open-sidebar="true"] {
    padding-left: unset;
  }

  .Toolbar__container,
  .Toolbar__root[data-open-sidebar="true"] .Toolbar__container {
    margin: 0 auto;
  }
}

@media screen and (max-width: 900px) {
  .Toolbar__container,
  .Toolbar__root[data-open-sidebar="true"] .Toolbar__container {
    border-top: 1px solid #e0e0e0;
    border-left: none;
    margin: 40px 0 0 0;

    width: 100%;
    max-width: unset;
  }
}

/* Tooltip */

.Toolbar__Tooltip_root {
  display: inline;
  position: relative;
}

.Toolbar__Tooltip_children {
  display: inline;
}

.Toolbar__Tooltip_tooltip {
  display: none;
  position: absolute;

  bottom: -22px;
  left: 20px;
  transform: translateX(-50%);

  color: #333;
  font-size: 12px;
  line-height: 16px;
  padding: 2px 8px;
  white-space: nowrap;

  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
}

@media (hover: hover) {
  .Toolbar__Tooltip_children:hover
    + .Toolbar__Tooltip_tooltip:not([data-disabled="true"]) {
    display: inline;
  }
}
