.Uploading__root {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  z-index: 10000;
  overflow: hidden;
  background-color: rgba(30, 30, 30, 0.5);

  font-size: 24px;
  font-weight: bold;
  color: #fff;

  display: flex;
  justify-content: center;
  align-items: center;
}
