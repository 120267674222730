.container {
  background: #f3f3f3;
  margin-top: 80px;
  padding: 24px 0;
  text-align: center;
}

.list {
  list-style: none;
  margin: 8px 0 0;
  padding: 0;
}

.list li {
  display: inline-block;
  margin-left: 24px;
}

.list li:first-child {
  margin-left: 0;
}

.list a {
  color: #555;
  font-size: 12px;
  text-decoration: none;
  text-transform: full-width;
}

.list a:hover {
  color: #000;
}

.contact {
  color: #555;
  font-size: 12px;
  margin-top: 16px;
}

.copy {
  color: #999;
  font-size: 10px;
  margin-top: 16px;
}

@media screen and (max-width: 768px) {
  .list li {
    display: inline-block;
    margin-left: 16px;
    line-height: 160%;
  }

  .list li:first-child {
    margin-left: 0;
  }
}
