.container {
  margin: 64px auto;
  padding: 16px;
  max-width: 680px;
}

.container li,
.container p {
  margin: 8px 0;
  line-height: 1.6;
}

.container h2 {
  margin-top: 48px;
}

.container h3 {
  margin: 0;
  /* margin: 32px 0 8px 0; */
  font-size: 1rem;
  font-weight: bold;
  /* font-weight: normal; */
  line-height: 1.6;
}

.container h3 + p {
  margin-top: 0;
}

.tar {
  font-size: 14px;
  text-align: right;
  margin-top: 32px;
}

.note {
  font-size: 14px;
  color: #999;
}
