.container {
  box-sizing: border-box;
  width: 960px;
  margin: 0 auto;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 64px 0 0;
}

.header h1 {
  margin: 0;
  margin-bottom: 8px;
}

.title {
  font-size: 18px;
  color: #333;
}

.help {
  margin: 0;
  margin-bottom: 1.5rem;
}

.help a {
  color: #555;
  font-size: 12px;
  text-decoration: none;
}

.help a:hover {
  color: #333;
  text-decoration: underline;
}

.table {
  display: table;
  width: 100%;
  border-collapse: collapse;
}

.thead th {
  border-bottom: 1px solid #333;
  box-sizing: border-box;
  font-size: 10px;
  padding: 8px 0;
  text-align: left;
}

.box td {
  border-bottom: 1px solid #eaeaea;
  box-sizing: border-box;
  font-size: 14px;
  padding: 24px 0;
  align-items: center;
}

.box:last-child td {
  border-bottom: none;
}

.box td.item {
  padding-right: 16px;
}

.couponCode {
  font-family: monospace;
  background-color: #eaeaea;
  border-radius: 4px;
  padding: 4px 8px;

  cursor: pointer;

  white-space: nowrap;
}

.couponCode del {
  text-decoration-thickness: 2px;
}

.couponCode:hover {
  opacity: 0.75;
}

.tdRevoke {
  text-align: right;
}

.box td {
  border-bottom: 1px solid #eaeaea;
}

@media screen and (max-width: 960px) {
  .container {
    width: 100%;
    padding: 0 64px;
  }

  .header {
    padding: 0 16px;
  }
}

@media screen and (max-width: 768px) {
  .container {
    padding: 0;
  }

  .table {
    width: 100%;
  }

  .table tr {
    width: 100%;
    display: block;
    padding: 16px;
    box-sizing: border-box;
    border-bottom: 1px solid #eaeaea;
  }

  .table tr:last-child {
    border-bottom: none;
  }

  .thead {
    display: none;
  }

  .box td {
    width: 100%;
    text-align: right;
    display: flex;
    justify-content: space-between;
    padding: 16px 0;
    border-bottom: 1px dashed #eaeaea;
  }

  .table tr:last-child td {
    border-bottom: 1px dashed #eaeaea;
  }

  .table tr:last-child td:last-child {
    border: none;
  }

  .box td:last-child {
    border-bottom: none;
  }

  .box td:before {
    content: attr(data-label);
    color: #333;
    font-size: 10px;
    font-weight: bold;
  }

  .box td.item {
    display: block;
    text-align: left;
    padding-right: 0;
  }

  .box td.publish {
    padding-right: 0;
  }

  .box td.price,
  .box td.figures,
  .box td.sales {
    padding-left: 0;
  }

  .txt {
    padding: 0 16px;
  }
}
