.wrap {
  box-sizing: border-box;
  width: 480px;
  margin: 80px auto;
}

@media screen and (max-width: 768px) {
  .wrap {
    width: 100%;
    margin: 32px auto 80px;
    padding: 0 8px;
  }
}

.ttl {
  font-size: 18px;
  text-align: center;
  margin-bottom: 64px;
}

.btn {
  border-radius: 8px;
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
  height: 40px;
  padding: 2px 16px 0;
  transition: 0.3s;
}

.btn:hover {
  opacity: 0.8;
  transition: 0.3s;
}

.btn:active {
  opacity: 0.3;
  transition: 0.3s;
}

.btn svg {
  margin-right: 4px;
  vertical-align: middle;
}

.btnGoogle {
  composes: btn;
  background: #fff;
  border: 1px solid #c4c4c4;
  color: #333;
}

.btnTwitter {
  composes: btn;
  background: #00acee;
  border: none;
  color: #fff;
  margin-left: 24px;
}

.btnSubmit {
  composes: btn;
  background: #9281ff;
  border: none;
  color: #fff;
  font-size: 14px;
  margin-top: 24px;
}

.border {
  display: flex;
  align-items: center;
  color: #999;
  font-size: 14px;
  margin: 32px 0;
}

.border:before {
  content: "";
  height: 1px;
  flex-grow: 1;
  background-color: #eaeaea;
  margin-right: 1rem;
}

.border:after {
  content: "";
  height: 1px;
  flex-grow: 1;
  background-color: #eaeaea;
  margin-left: 1rem;
}

.label {
  display: block;
  color: #333;
  font-size: 12px;
  font-weight: bold;
  margin-top: 24px;
}

.input {
  font-size: 14px;
  border: solid 1px #c4c4c4;
  border-radius: 8px;
  box-sizing: border-box;
  margin-top: 8px;
  padding: 0 16px;
  width: 100%;
  height: 40px;
}

.input:hover {
  background: #fff;
  border: solid 1px #9281ff;
  box-shadow: 0px 0px 8px rgba(146, 129, 255, 0.5);
  transition: 0.3s;
}

.input:focus {
  outline: none;
  background: #fff;
  border: solid 1px #9281ff;
  box-shadow: 0px 0px 8px rgba(146, 129, 255, 0.5);
  transition: 0.3s;
}

.login {
  font-size: 14px;
  text-align: right;
  margin-top: 64px;
}

.login a {
  color: #2f80ed;
  text-decoration: underline;
}

.login a:hover {
  text-decoration: none;
}

@media screen and (max-width: 768px) {
  .ttl {
    margin-bottom: 32px;
  }
  .sns {
    text-align: center;
  }
  .btn {
    display: block;
    margin: 0 auto;
  }
  .btnTwitter {
    margin: 16px auto 0;
  }
  .btnSubmit {
    margin-top: 32px;
  }
  .login {
    text-align: center;
  }
}
