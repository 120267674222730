.ContentEditable__root {
  padding: 40px;
  background-color: #fff;
  border: none;
  border-radius: 8px;
  outline: none;

  box-sizing: border-box;
  min-height: 100vh;
}

@media screen and (max-width: 414px) {
  .ContentEditable__root {
    padding: 24px 16px;
  }
}
