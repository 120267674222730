.container {
  margin: 0 64px;
  margin-top: 32px;
  position: relative;
}

.banner {
  width: 100%;
  border-radius: 8px;
}

.bannerDefault {
  width: 100%;
  height: 100%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bannerTitle {
  font-size: 40px;
  font-weight: bold;
  color: #333;
}

.buttonArea {
  position: absolute;
  bottom: 24px;
  right: 24px;
}

.btn {
  background: #9281ff;
  border: 1px solid #9281ff;
  border-radius: 8px;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  width: 160px;
  height: 40px;
  margin: 0;
  padding: 2px 16px 0;
  transition: 0.2s;
  box-sizing: border-box;
  user-select: none;
}

.btn[data-secondary="true"] {
  background: #fff;
  color: #333;
  border-color: #c5c5c5;
}

.btn:disabled {
  background: #d3d3d3;
  border-color: #d3d3d3;
  color: #fff;
  cursor: unset;
}

.btn[data-full-width="true"],
.loading[data-full-width="true"] {
  width: 100%;
}

.btn:hover {
  opacity: 0.7;
}

.btn:hover:disabled {
  opacity: 1;
}

.btn:active {
  opacity: 0.3;
}

.btn:hover[data-secondary="true"] {
  opacity: unset;
  background-color: #f5f5f5;
}

.btn:active {
  opacity: unset;
  background-color: #f5f5f5;
}

.btnOfficial {
  composes: btn;
  background: rgba(0, 0, 0, 0.5);
  border: none;
  margin-left: 24px;
  padding: 0;
}

.btnOfficial a {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  color: #fff;
  width: 100%;
  height: 40px;
  padding: 2px 16px 0;
  text-decoration: none;
}

.btnOfficial .img {
  display: none;
}

.btnWrite {
  composes: btn;
  background: rgba(255, 255, 255, 1);
  border: none;
  color: #333;
}

.bannerFrame {
  width: 100%;
  aspect-ratio: 1000 / 200;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.jenre {
  font-size: 48px;
  text-align: center;
  margin: 0;
}

@keyframes skeleton-animation {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(100%);
  }
}

.bannerFrame[data-loding="true"]::before {
  content: "";
  display: block;
  height: 100%;
  width: 100%;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.8),
    transparent
  );
  position: absolute;
  top: 0;
  left: 0;
  animation: skeleton-animation 1s linear infinite;
}

@media screen and (max-width: 1080px) {
  .container {
    margin-top: 64px;
  }
}

@media screen and (max-width: 960px) {
  .container {
    margin: 0 32px;
    margin-top: 64px;
  }

  .bannerTitle {
    font-size: 32px;
  }
}

@media screen and (max-width: 767px) {
  .container {
    margin: 0 16px;
    margin-top: 64px;
  }

  .banner {
    border-radius: 4px;
  }

  .bannerTitle {
    font-size: 20px;
  }

  .buttonArea {
    bottom: 12px;
    right: 8px;
  }

  .btnOfficial {
    width: auto;
    height: auto;
  }

  .btnOfficial a {
    padding: 6px 8px 4px;
    height: auto;
  }

  .btnOfficial .txt {
    display: none;
  }

  .btnOfficial .img {
    display: inline-block;
  }

  .btnWrite {
    display: none;
  }
}

@media screen and (max-width: 320px) {
  .bannerTitle {
    font-size: 18px;
  }
}
