.container {
  margin: 64px auto;
  padding: 16px;
  max-width: 680px;
}

.category {
  line-height: 180%;
  margin: 48px 0 0;
}

.list {
  border-top: 1px solid #f1f1f1;
  box-sizing: border-box;
  list-style: none;
  margin: 16px 0 0;
  padding: 0;
}

.item {
  border-bottom: 1px solid #f1f1f1;
  margin: 0;
  padding: 0;
  line-height: 160%;
}

.sub {
  font-size: 14px;
  margin: 16px 0 8px;
  padding-left: 1rem;
}

.btn {
  cursor: pointer;
  font-size: 18px;
  margin: 0;
  padding: 16px;
  position: relative;
  transition: 0.3s;
}

.btn:hover {
  background: #ded9fe;
  transition: 0.3s;
}

.btn:after {
  content: "";
  width: 14px;
  height: 14px;
  background: url(./IconArrowDown.svg) no-repeat;
  background-size: contain;
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
}

.btn[data-open="true"]:after {
  background: url(./IconArrowUp.svg) no-repeat;
  background-size: contain;
}

.toggle {
  padding: 0 16px 16px;
}

.toggle p {
  margin: 0;
  line-height: 160%;
}

.toggle img {
  margin: 8px 0;
  max-width: 100%;
}

.toggle ul {
  margin-top: 16px;
  padding: 0 0 0 1rem;
}

.indent {
  font-size: 14px;
  padding-left: 1rem;
}

.toggle p.note,
.inner p.note {
  color: #eb5757;
  font-size: 12px;
  margin-top: 8px;
}

.link {
  display: flex;
  align-items: center;
}

.link svg {
  margin-left: 4px;
}

.inner p {
  line-height: 1.4;
}

@media screen and (max-width: 768px) {
  .container {
    margin-top: 16px;
    padding: 16px 0;
  }

  .category {
    padding: 0 16px;
    box-sizing: border-box;
  }

  .ttl {
    padding: 0 16px;
    box-sizing: border-box;
  }

  .lead {
    padding: 0 16px;
    box-sizing: border-box;
  }

  .btn {
    padding-right: 48px;
  }

  .btn:hover {
    background: #fff;
    transition: 0s;
  }

  .inner {
    padding: 0 16px;
    box-sizing: border-box;
  }

  p {
    font-size: 14px;
    line-height: 140%;
  }

  .toggle ul {
    font-size: 14px;
  }
}
