.container {
  width: 100%;
  margin-top: 32px;
  padding: 0 64px;
  box-sizing: border-box;
}

@media screen and (max-width: 767px) {
  .container {
    padding: 0 16px;
  }
}

.button {
  background: #fff;
  border: 1px solid #eaeaea;
  border-radius: 8px;
  color: #333;

  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  line-height: 16px;
  height: 40px;
  padding: 0 64px;
  transition: 0.5s;

  margin: 0 auto;
}

.button:hover {
  background: #f1f1f1;
}

.moreArea {
  margin: 24px auto 0;
}

.loading {
  margin-top: 24px;
  display: flex;
  justify-content: center;
}

.hidden {
  display: none;
}
