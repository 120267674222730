.container {
  width: 700px;
  margin: 104px auto 0;
}

@media screen and (max-width: 1200px) {
  .container {
    width: 64vw;
    margin-left: 32px;
    position: relative;
    left: 240px;
  }
  .inner {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .container {
    margin: 72px 0 0;
    padding: 0 16px;
    box-sizing: border-box;
    position: static;
    width: 100%;
  }
}

@media print {
  .container {
    margin: 0;
    padding: 48px;
    width: 100%;
    box-sizing: border-box;
  }
}
