.container {
  background: #fff;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  min-height: 480px;
}

.header {
  position: fixed;
  top: 0;
  width: 100%;
}

.searchArea {
  position: relative;
}

.searchArea:before {
  content: "";
  display: inline-block;
  width: 25px;
  height: 25px;
  background: url(./IconSearch.svg) no-repeat;
  background-size: cover;
  position: absolute;
  top: 50%;
  left: 16px;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
}

.search {
  padding: 16px 32px 16px 48px;
  border: none;
  border-top: 1px solid #eaeaea;
  border-bottom: 1px solid #eaeaea;
  display: block;
  width: 100%;
  outline: none;
  box-sizing: border-box;
  background: #f3f3f3;
  font-size: 14px;
}

.ttl {
  font-size: 16px;
  margin: 0;
  padding: 16px;
  text-align: center;
  background: #fff;
}

.list {
  flex: 1;
  margin: 99px 0 0;
  padding: 0;
  list-style: none;
  overflow: auto;
  max-height: 381px;
}

.list li {
  background: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 48px;
  padding: 0 32px;
  transition: 0.3s;
}

.list li:hover {
  background: #ded9fe;
  transition: 0.3s;
}

@media screen and (max-width: 414px) {
  .ttl {
    width: calc(100% - 32px);
    box-sizing: border-box;
  }

  .search {
    width: calc(100% - 32px);
  }

  .list {
    max-width: auto;
  }

  .list li {
    padding: 0 16px;
    line-height: 140%;
  }
}
