.btn {
  border-radius: 8px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
  height: 40px;
  padding: 0 16px;
  transition: 0.3s;
}

.btn:hover {
  opacity: 0.8;
  transition: 0.3s;
}

.btn:active {
  opacity: 0.3;
  transition: 0.3s;
}

.btn svg {
  margin-right: 4px;
  fill: #333;
}

.btnShare {
  composes: btn;
  background: #fff;
  border: 1px solid #c4c4c4;
  border-radius: 8px;
  color: #333;
  width: 160px;
}

@media screen and (max-width: 414px) {
  .btn {
    margin: 0 auto;
  }
}
