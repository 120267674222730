.container {
  padding-bottom: 160px;
}

.projectTitle {
  display: none;
}

.h1 {
  font-size: 32px;
  font-weight: normal;
  line-height: 160%;
  margin: 2.8rem 0 0;
  word-break: break-word;
  margin-bottom: 16px;
  padding-bottom: 8px;
  border-bottom: 1px solid #e2e2e2;
}

.h2 {
  font-size: 24px;
  font-weight: normal;
  line-height: 160%;
  margin: 2.4rem 0 0;
  word-break: break-word;
}

.h3 {
  font-size: 20px;
  font-weight: normal;
  line-height: 160%;
  margin: 2rem 0 0;
  word-break: break-word;
}

.notes {
  margin: 1rem 0;
  padding: 24px;
  border-radius: 8px;
  background: #f8f8f8;
  color: #777;
  font-size: 12px;
  line-height: 160%;
}

.txt {
  font-size: 16px;
  line-height: 180%;
  margin: 4px 0;
  min-height: 1rem;
  word-break: break-word;
  display: block;
}

a {
  color: #2f80ed;
}

.bold {
  font-weight: bold;
}

.underline {
  text-decoration: underline;
}

.img {
  width: 100%;
  margin: 16px 0;
}

.img img {
  border-radius: 8px;
  width: 100%;
}

.img img[data-size="small"] {
  width: 50%;
}

.libretto {
  font-size: 48px;
  line-height: 140%;
  margin: 0;
}

@media screen and (max-width: 768px) {
  .container {
    padding-top: 0;
    padding-bottom: 80px;
  }

  .h1 {
    font-size: 24px;
    line-height: 140%;
    padding-bottom: 4px;
    margin-bottom: 8px;
  }

  .h2 {
    font-size: 18px;
    line-height: 140%;
  }

  .h3 {
    font-size: 16px;
    line-height: 140%;
  }

  .notes {
    padding: 16px;
  }

  .txt {
    font-size: 14px;
    line-height: 160%;
    min-height: 25px;
  }

  .img {
    margin: 16px 0;
  }

  .libretto {
    font-size: 32px;
    line-height: 130%;
  }
}

@media print {
  .h1 {
    font-size: 24px;
  }

  .h2 {
    font-size: 18px;
  }

  .h3 {
    font-size: 16px;
  }

  .notes {
    padding: 16px;
  }

  .txt {
    font-size: 14px;
  }

  .projectTitle {
    display: block;
    margin: 0 0 8px 0;
    font-size: 14px;
  }
}
