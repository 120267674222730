.Footer__root {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 8000;

  background-color: #fff;
  font-size: 12px;
  padding: 12px 80px;
  margin: 0;
  color: #777;
  border-top: 1px solid #e0e0e0;
}

.Footer__container {
  max-width: calc(700px + 80px);
  box-sizing: border-box;
  padding: 0;
  margin: 0 auto;

  display: flex;
}

.Footer__lastSaved {
  display: flex;
  align-items: center;
}

.Footer__iconSaved {
  margin-left: 6px;
  fill: #777;
}

.Footer__count {
  margin-left: auto;
}

@media screen and (max-width: 1440px) {
  .Footer__root[data-open-sidebar="true"] {
    margin-left: 240px;
  }
}

@media screen and (max-width: 1120px) {
  .Footer__root {
    margin-left: 0;
    padding: 2px 0;
  }

  .Footer__root[data-open-sidebar="true"] {
    margin-left: 0;
  }

  .Footer__container {
    margin: 0 auto;
    width: auto;
    max-width: 800px;
  }
}
