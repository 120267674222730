.container {
  position: relative;
  margin-top: 16px;
}

.categories {
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow-x: scroll;
  overflow-y: hidden;

  -ms-overflow-style: none;
  scrollbar-width: none;
}

.categories::-webkit-scrollbar {
  display: none;
}

.box {
  position: relative;
}

.overlay {
  background: rgb(255, 255, 255);
  width: 40px;
  height: 20px;
  position: absolute;
  top: 0;
}

.overlayRight {
  composes: overlay;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  right: 0;
}

.overlayLeft {
  composes: overlay;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  left: 0;
}

.arrow {
  position: absolute;
  width: 18px;
  top: 0px;
  cursor: pointer;
  z-index: 10;
}

.arrowRight {
  composes: arrow;
  right: 0;
}

.arrowLeft {
  composes: arrow;
  left: 0;
}

.list {
  display: flex;
  margin: 0;
  padding: 0;
  padding-right: 32px;
}

.list::-webkit-scrollbar {
  display: none;
}

.item {
  margin: 0;
  font-size: 12px;
  line-height: 20px;
  font-weight: bold;
}

.ttl {
  composes: item;
  color: inherit;
}

.category {
  composes: item;
  margin-left: 16px;
  color: #999;
  transition: color 0.4s cubic-bezier(0.22, 1, 0.36, 1);
  text-decoration: none;
}

@media (hover: hover) {
  .category:hover {
    color: #333;
  }
}

@media screen and (max-width: 1080px) {
  .container {
    padding: 0 64px;
  }

  .arrowRight,
  .overlayRight {
    right: 64px;
  }

  .arrowLeft,
  .overlayLeft {
    left: 64px;
  }
}

@media screen and (max-width: 768px) {
  .container {
    padding: 0 16px;
  }

  .arrowRight,
  .overlayRight {
    right: 16px;
  }

  .arrowLeft,
  .overlayLeft {
    left: 16px;
  }
}
