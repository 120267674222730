.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  border-bottom: 1px solid #eaeaea;
  box-sizing: border-box;
  width: calc(100% - 240px);
  height: 52px;
  padding: 0 16px 0 8px;
  position: fixed;
  top: 0;
  left: 240px;
  z-index: 999;
}

.list {
  display: flex;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 4px 0;
}

.btnMenu {
  display: none;
  background: none;
  border: none;
}

.btn {
  border-radius: 8px;
  box-sizing: border-box;
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
  height: 32px;
  padding: 0 16px;
  transition: 0.2s;
}

.btn:hover {
  opacity: 0.8;
  transition: 0.2s;
}

.btn:active {
  opacity: 0.3;
  transition: 0.2s;
}

.btnRelease {
  composes: btn;
  background: #9281ff;
  border: none;
  color: #fff;
  margin-left: 16px;
}

.help {
  fill: #333;
  transition: 0.3s;
}

.help:hover {
  fill: #9281ff;
  transition: 0.2s;
}

.toolBar {
  border-right: 1px solid #eaeaea;
  display: flex;
  align-items: center;
  box-sizing: border-box;
}

.buttons {
  display: flex;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0 8px 0 0;
}

.buttons li {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 40px;
  min-height: 52px;
  user-select: none;
}

.buttons li:hover,
.active {
  background: rgba(146, 129, 255, 0.3);
}

.buttons li[data-disabled="true"] {
  opacity: 0.5;
  cursor: auto;
}

.buttons li[data-disabled="true"]:hover {
  background: none;
}

.buttonsRight {
  composes: buttons;
  border-left: 1px solid #eaeaea;
  padding-left: 8px;
}

@media screen and (max-width: 768px) {
  .container {
    width: 100%;
    padding: 4px 16px 4px 0;
    left: 0;
  }

  .btnMenu {
    display: block;
    padding: 0 16px;
  }

  .toolBar {
    background: #fff;
    position: fixed;
    top: 52px;
    left: 0;
    border-bottom: 1px solid #eaeaea;
    border-right: none;
    width: 100%;
  }

  .buttonsRight {
    border-right: 1px solid #eaeaea;
    padding-right: 8px;
  }

  .buttons li {
    min-height: 40px;
  }
}
