.user {
  border: solid 1px #eaeaea;
  border-radius: 50px;
  cursor: pointer;
  margin-left: 16px;
  padding: 0;
  width: 40px;
  height: 40px;
  overflow: hidden;
  transition: 0.5s;
}

.user[data-variant="small"] {
  width: 32px;
  height: 32px;
}

.user:hover {
  opacity: 0.7;
  transition: 0.5s;
}

.user:active {
  opacity: 0.3;
  transition: 0;
}

.user img {
  user-select: none;
  width: 100%;
}

.userMenu {
  background: #fff;
  border-radius: 8px;
  border: 1px solid #eaeaea;
  width: 240px;
  position: absolute;
  top: 64px;
  right: 24px;
  box-shadow: 0px 0px 1.7px rgba(0, 0, 0, 0.04),
    0px 0px 5.6px rgba(0, 0, 0, 0.06), 0px 0px 25px rgba(0, 0, 0, 0.1);
  z-index: 10;
  padding: 8px 0;
}

.userMenu[data-variant="small"] {
  top: 48px;
  right: 0;
}

.userMenu[data-sp="true"] {
  top: 48px;
  right: 6px;
}

.userMenu::before {
  content: "";
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  right: 12px;
  top: -12px;
  border-right: 8px solid transparent;
  border-bottom: 12px solid #eaeaea;
  border-left: 8px solid transparent;
}

/* beforeで本体の三角を表現 */
.userMenu::after {
  content: "";
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  right: 12px;
  top: -9px;
  border-right: 8px solid transparent;
  border-bottom: 12px solid #fff;
  border-left: 8px solid transparent;
}

.userMenuContent {
  font-size: 14px;
  list-style: none;
  margin: 0 24px;
  padding: 8px 0;
}

.userMenuContent li {
  /* margin-top: 8px; */
  padding: 8px 0;
}

.userMenuContent a {
  color: #333;
  text-decoration: none;
  display: block;
}

.userMenuContent a:hover {
  color: #9281ff;
}

.userMenuContent + .userMenuContent {
  border-top: 1px solid #eaeaea;
}

.userMenuContent:last-child {
  padding-bottom: 2px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 5;

  user-select: none;
}
