.container {
  background: #fff;
}

.ttl {
  border-bottom: 1px solid #eaeaea;
  font-size: 16px;
  margin: 0;
  padding: 16px;
  text-align: center;
}

.box {
  box-sizing: border-box;
  padding: 24px;
}

.txt {
  font-size: 16px;
  line-height: 160%;
  margin: 0;
}

.txt a,
.agree a {
  color: #2f80ed;
}

.link {
  composes: txt;
  margin-top: 16px;
  text-align: right;
}

.list {
  font-size: 14px;
  list-style: none;
  margin: 24px 0 0;
  padding: 0 0 0 1rem;
  text-indent: -1rem;
  line-height: 140%;
}

.list li {
  margin-top: 8px;
}

.agree {
  text-align: center;
  margin-top: 48px;
}

.agree p {
  margin: 0;
  line-height: 160%;
}

.btn {
  background: #9281ff;
  border: 1px solid #9281ff;
  border-radius: 8px;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  margin-top: 24px;
  padding: 0 16px;
  height: 40px;
  transition: 0.2s;
}

.btn:hover {
  opacity: 0.8;
  transition: 0.2s;
}

.btn:active {
  opacity: 0.3;
}

.spi {
  display: none;
}

@media screen and (max-width: 768px) {
  .container {
    height: auto;
    padding-bottom: 64px;
  }

  .box {
    box-sizing: border-box;
    padding: 24px 16px;
  }

  .spi {
    display: inline-block;
  }
}
