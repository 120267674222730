.container {
  border-bottom: solid 1px #eaeaea;
  box-sizing: border-box;
  background: #fff;

  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  padding: 8px 24px;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
}

.scenario {
  display: block;
}

.ttl {
  font-size: 14px;
  font-weight: bold;
  color: #9281ff;
  border: none;

  margin: 0;
  padding: 0;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.version {
  font-size: 12px;
  color: #999;
}

.booklet {
  font-size: 16px;
  width: auto;
  display: flex;
  align-items: center;
  margin: 0;
}

.open {
  display: none;
}

.btn {
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  padding: 0 16px;
  transition: 0.1s;
}

.btnShare {
  composes: btn;
  background: #fff;
  border: 1px solid #f1f1f1;
  color: #333;
  font-weight: bold;
  margin: 0;
}

.btnShare:hover {
  background: #9281ff;
  border: 1px solid #9281ff;
  color: #fff;
}

.btnShare:active {
  opacity: 0.3;
  transition: 0.1s;
}

@media screen and (max-width: 768px) {
  .container {
    padding: 4px 8px;
  }

  .scenario {
    display: none;
  }

  .open {
    display: flex;
    height: 40px;
    align-items: center;
  }

  .booklet {
    font-size: 14px;
  }

  .btnToggle {
    display: flex;
    align-items: center;
    color: #999;
    font-size: 14px;
    margin: 0;
    padding: 0 8px;
  }

  .btnToggle svg {
    margin-right: 4px;
  }

  .btn {
    height: 32px;
    padding: 0 8px;
  }
}

@media print {
  .container {
    display: none !important;
  }
}
