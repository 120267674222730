.container {
  box-sizing: border-box;
}

.list {
  list-style: none;
  margin: 48px 0 0;
  padding: 0;
}

.list li {
  margin-top: 24px;
}

.flex {
  display: flex;
  align-items: start;
}

.flexBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nameRow {
  composes: flexBetween;
  margin: 0;
}

.delete {
  background: none;
  border: 0;
  cursor: pointer;
  margin-left: 8px;
  transition: 0.3s;
}

.delete svg {
  fill: #999;
}

.delete:hover svg {
  fill: #333;
  transition: 0.3s;
}

.img {
  margin-right: 16px;
}

.img svg {
  fill: #333;
}

.imgPicture,
.imgMovie,
.imgMusic,
.imgPdf,
.imgZip {
  composes: img;
}

.description {
  width: 100%;
}

.name {
  font-size: 16px;
  word-break: break-all;
  line-height: 24px;
  text-align: left;
  margin: 0;
}

.bar {
  background: #f1f1f1;
  border-radius: 50px;
  height: 8px;
  width: 100%;
}

.date {
  font-size: 12px;
  line-height: 17px;
  color: #999;
  text-align: left;
}

@media screen and (max-width: 768px) {
  .container {
    width: 100%;
  }
}
