.container {
  box-sizing: border-box;
  width: 640px;
  margin: 32px auto 0;
}

.note {
  background: #f8f8f8;
  border-radius: 8px;
  box-sizing: border-box;
  font-size: 12px;
  margin-top: 32px;
  padding: 24px;
}

.note p {
  line-height: 140%;
  margin: 0 0 16px;
  vertical-align: middle;
}

.note p:last-child {
  margin-bottom: 0;
}

.note a {
  color: #2f80ed;
  text-decoration: underline;
  vertical-align: middle;
  word-break: break-all;
}

.note a:hover {
  text-decoration: none;
}

.note svg {
  vertical-align: middle;
  margin-left: 4px;
}

.label {
  display: block;
  font-size: 12px;
  font-weight: bold;
  margin: 32px 0 0;
}

.txt {
  font-size: 16px;
  margin-top: 8px;
  line-height: 160%;
}

.btn {
  background: #9281ff;
  border: 1px solid #9281ff;
  border-radius: 8px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  height: 40px;
  margin: 48px auto 0;
  padding: 0 16px;
  transition: 0.3s;
}

.input {
  font-size: 14px;
  border: solid 1px #c4c4c4;
  border-radius: 8px;
  box-sizing: border-box;
  margin-top: 8px;
  padding: 0 16px;
  width: 100%;
  height: 40px;
}

.input:hover {
  background: #fff;
  border: solid 1px #9281ff;
  box-shadow: 0px 0px 8px rgba(146, 129, 255, 0.5);
  transition: 0.3s;
}

.input:focus {
  outline: none;
  background: #fff;
  border: solid 1px #9281ff;
  box-shadow: 0px 0px 8px rgba(146, 129, 255, 0.5);
  transition: 0.3s;
}

.description {
  -webkit-appearance: none;
  appearance: none;
  background: #fff;
  border: solid 1px #c4c4c4;
  border-radius: 8px;
  box-sizing: border-box;
  width: 100%;
  margin-top: 8px;
  padding: 16px;
}

.description:hover {
  background: #fff;
  border: solid 1px #9281ff;
  box-shadow: 0px 0px 8px rgba(146, 129, 255, 0.5);
  transition: 0.3s;
}

.description:focus {
  outline: none;
  background: #fff;
  border: solid 1px #9281ff;
  box-shadow: 0px 0px 8px rgba(146, 129, 255, 0.5);
  transition: 0.3s;
}

.btn svg {
  margin-right: 4px;
}

.btn svg {
  fill: #9281ff;
}

.btn:hover {
  opacity: 0.8;
  transition: 0.3s;
}

.btn:active {
  opacity: 0.3;
}

.btn:hover svg {
  fill: #fff;
  transition: 0.3s;
}

.comments {
  color: #999;
  font-size: 12px;
  line-height: 140%;
  margin-top: 8px;
}

.required {
  color: #eb5757;
  font-weight: normal;
}

@media screen and (max-width: 768px) {
  .container {
    box-sizing: border-box;
    margin-top: 24px;
    padding: 0 16px;
    width: 100%;
  }

  .note {
    font-size: 12px;
    padding: 16px;
  }
}
