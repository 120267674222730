.container {
  background: #fff;
  position: relative;
}

.ttl {
  border-bottom: 1px solid #eaeaea;
  box-sizing: border-box;
  font-size: 16px;
  margin: 0;
  padding: 16px;
  text-align: center;
  position: fixed;
  width: 100%;
  background: #fff;
}

.box {
  box-sizing: border-box;
  padding: 72px 32px 48px;
}

.boxBtn {
  composes: box;
  background: #fff;
  border-top: 1px solid #eaeaea;
  box-sizing: border-box;
  padding: 12px 32px;
  display: flex;
  justify-content: flex-end;
  position: fixed;
  bottom: 0;
  width: 100%;
}

.img {
  width: 160px;
  margin: 0 auto;
  box-sizing: border-box;
}

.img img {
  width: 100%;
}

.txt {
  font-size: 14px;
  text-align: center;
  line-height: 180%;
  margin: 16px 0 0;
}

.shareArea {
  text-align: center;
  margin-top: 16px;
}

.btn {
  border-radius: 8px;
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
  height: 36px;
  padding: 2px 16px 0;
  transition: 0.5s;
}

.btn:hover {
  opacity: 0.7;
  transition: 0.5s;
}

.btn svg {
  vertical-align: middle;
  margin-right: 4px;
}

.btnTweet {
  composes: btn;
  background: #00acee;
  border: none;
  color: #fff;
  font-size: 14px;
}

.btnShare {
  composes: btn;
  background: #fff;
  border: 1px solid #c4c4c4;
  color: #333;
  font-size: 14px;
  margin-left: 24px;
}

.btnCancel {
  composes: btn;
  background: #f1f1f1;
  border: none;
  color: #333;
  font-size: 14px;
}

.btnAgree {
  composes: btn;
  background: #9281ff;
  border: none;
  color: #fff;
  font-size: 14px;
  margin-left: 24px;
}

.btnItem {
  composes: btn;
  background: #9281ff;
  border: none;
  color: #fff;
  display: inline-block;
  font-size: 14px;
  height: 40px;
  padding: 0;
}

.btnItem a {
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  height: 40px;
  padding: 0 16px;
}

@media screen and (max-width: 768px) {
  .container {
    width: 100%;
    height: auto;
    padding-bottom: 16px;
    position: relative;
    box-sizing: border-box;
  }

  .ttl {
    position: static;
  }

  .box {
    padding: 16px 16px 32px;
  }

  .boxBtn {
    position: absolute;
    bottom: 0;
    left: auto;
    width: 100%;
    padding: 16px;
  }
}
