.container {
  background: #fff;
}

.ttl {
  border-bottom: 1px solid #eaeaea;
  font-size: 16px;
  margin: 0;
  padding: 16px;
  text-align: center;
}

.body {
  margin: 0;
  padding: 0 32px 32px;
}

.input {
  font-size: 14px;
  border: solid 1px #c4c4c4;
  border-radius: 8px;
  box-sizing: border-box;
  margin-top: 8px;
  padding: 0 16px;
  width: 100%;
  height: 40px;
}

.input:hover {
  background: #fff;
  border: solid 1px #9281ff;
  box-shadow: 0px 0px 8px rgba(146, 129, 255, 0.5);
  transition: 0.3s;
}

.input:focus {
  outline: none;
  background: #fff;
  border: solid 1px #9281ff;
  box-shadow: 0px 0px 8px rgba(146, 129, 255, 0.5);
  transition: 0.3s;
}

.label {
  display: block;
  color: #333;
  font-size: 12px;
  font-weight: bold;
  margin-top: 32px;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.txt {
  font-size: 14px;
  line-height: 140%;
}

.btn {
  background: #9281ff;
  border: 1px solid #9281ff;
  border-radius: 8px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  height: 40px;
  margin-left: 16px;
  padding: 0 16px;
  transition: 0.3s;
  min-width: 4rem;
}

.btn:hover {
  opacity: 0.8;
  transition: 0.3s;
}

.btn:active {
  opacity: 0.3;
  transition: 0.3s;
}

.btnSave {
  composes: btn;
  margin-top: 8px;
}

.btnDelete {
  composes: btn;
  color: #eb5757;
  background: #fff;
  border: 1px solid #c4c4c4;
}

.btnDelete:hover {
  background: #eb5757;
  border: 1px solid #eb5757;
  color: #fff;
  transition: 0.3s;
  opacity: 1;
}

.spi {
  display: none;
}

@media screen and (max-width: 768px) {
  .container {
    height: auto;
    padding-bottom: 64px;
  }

  .body {
    margin: 32px 0 0;
    padding: 0 16px;
  }
}

@media screen and (max-width: 414px) {
  .container {
    padding-bottom: 32px;
  }
  .flex {
    display: block;
  }

  .btnArea {
    text-align: center;
  }

  .btn {
    margin-left: 0;
  }

  .spi {
    display: inline-block;
  }
}
