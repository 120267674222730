.container {
  box-sizing: border-box;
  background: #fff;
  width: 240px;
  margin-top: 64px;
  padding: 32px 24px 240px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  transition: 0.3s;
  z-index: 9998;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.container::-webkit-scrollbar {
  display: none;
}

.container[data-open="true"] {
  left: 0;
  transition: 0.3s;
}

.ttl {
  font-size: 16px;
  font-weight: bold;
  margin: 0 0 24px;
}

.bookletTitle {
  display: flex;
  align-items: center;
  padding: 0;
  margin-top: 24px;
  font-size: 14px;
  cursor: pointer;
  user-select: none;
}

.bookletTitle[data-active="true"] {
  font-weight: bold;
}

.list {
  list-style: none;
  margin-left: 24px;
  padding: 0;
  font-size: 14px;
  user-select: none;
}

.list li {
  margin-top: 16px;
}

.list span,
.bookletTitle span {
  color: #333;
  text-decoration: none;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  cursor: pointer;
}

.list span:hover {
  font-weight: bold;
}

.ancH2 span {
  padding-left: 1em;
}

@media screen and (max-width: 768px) {
  .container {
    top: -32px;
    left: -100%;
    width: 80%;
    box-shadow: 0px 1.1px 3.1px rgba(0, 0, 0, 0.025),
      0px 2.7px 7.7px rgba(0, 0, 0, 0.035),
      0px 5.5px 15.8px rgba(0, 0, 0, 0.045),
      0px 11.3px 32.5px rgba(0, 0, 0, 0.055), 0px 31px 89px rgba(0, 0, 0, 0.08);
    padding: 32px 24px 80px;
  }

  .container[data-open="true"] {
    left: 0;
  }

  .overlay[data-open="false"] {
    display: none;
  }

  .overlay[data-open="true"] {
    display: block;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
  }
}

@media print {
  .container,
  .overlay {
    display: none !important;
  }
}
