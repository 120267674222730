.wrap {
  width: 100%;
  border-top: 1px solid #eaeaea;
  margin: 0;
  margin-top: 32px;
}

.container {
  width: 100%;
  max-width: 1500px;
  margin: 0 auto;
  padding: 32px 64px 0;
  box-sizing: border-box;
}

@media screen and (max-width: 767px) {
  .container {
    padding: 32px 16px 0;
  }
}

.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.category {
  font-size: 18px;
  margin: 0;
}

.list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  grid-gap: 16px;
  list-style: none;
  margin: 16px 0 0;
  padding: 0;
}

@media screen and (max-width: 767px) {
  .list {
    display: block;
  }
}

.list li {
  cursor: pointer;
}

@media screen and (max-width: 767px) {
  .list li {
    margin-top: 16px;
  }
}

.thumbnail {
  width: 100%;
  padding-top: calc(56.25% - 2px);
  position: relative;
  overflow: hidden;
  background: #efefef;
  border: solid 1px #efefef;
  border-radius: 8px;
  box-sizing: border-box;
}

.thumbnail img {
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
}

@media (hover: hover) {
  .list li:hover .thumbnail {
    border: solid 1px #9281ff;
    box-shadow: 0px 0px 8px rgba(146, 129, 255, 0.5);
    transition: 0.3s cubic-bezier(0.22, 1, 0.36, 1);
  }
}
