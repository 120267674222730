.main {
  width: 760px;
  margin: 0 auto 64px;
  padding: 0 280px 160px 0;

  overflow: hidden;
  box-sizing: border-box;
  position: relative;
}

.side {
  width: 240px;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  right: 0;
}

.spb {
  display: none;
}

@media screen and (max-width: 768px) {
  .main,
  .side {
    width: 100%;
    margin-bottom: 120px;
    padding: 0;
  }

  .side {
    position: static;
  }

  .spb {
    display: block;
  }
}
