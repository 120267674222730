.container {
  box-sizing: border-box;
}

.header {
  width: 960px;
  margin: 64px auto 0;
  box-sizing: border-box;
}

.ttl {
  color: #333;
  font-size: 18px;
  text-align: center;
}

.nav {
  border-bottom: 1px solid #f1f1f1;
}

.navBlank {
  border-bottom: 1px solid #f1f1f1;
  margin-top: 32px;
}

.list {
  width: 960px;
  margin: 64px auto 0;
  display: flex;
  list-style: none;
  padding: 0;
  box-sizing: border-box;
}

.list li {
  font-size: 14px;
  color: #999;
  cursor: pointer;
  padding: 0 32px;
  height: 40px;
  border-bottom: 4px solid #fff;
  transition: 0.2s;
  line-height: 160%;
}

.list li:hover {
  color: #9281ff;
  font-weight: bold;
  transition: 0.2s;
}

.list li.active {
  color: #9281ff;
  font-weight: bold;
  border-bottom: 4px solid #9281ff;
}

@media screen and (max-width: 768px) {
  .header {
    width: 100%;
    padding: 0 16px;
  }

  .list {
    width: 100%;
    margin-top: 32px;
  }

  .list li {
    width: 100%;
    text-align: center;
    padding-bottom: 8px;
    display: flex;
    align-items: center;
  }
}
