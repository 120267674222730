.btnDropbox {
  display: none;
}

.list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  grid-gap: 24px;
  white-space: nowrap;
  margin: 0;
  padding: 0;
  list-style: none;
  width: 100%;
}

.listClosed {
  composes: list;
}

.label {
  display: block;
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 8px;
}

.genre {
  overflow: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.searchArea {
  position: relative;
}

.searchIcon {
  position: absolute;
  top: 8px;
  left: 16px;
  opacity: 1;
  transition: 0.3s;
}

input:focus + .searchIcon {
  opacity: 0;
  transition: 0.3s;
}

form {
  width: 100%;
}

.search {
  -webkit-appearance: none;
  appearance: none;
  background: #f3f3f3;
  border: solid 1px transparent;
  border-radius: 8px;
  color: #333;
  width: 100%;
  height: 40px;
  padding: 0 16px 0 48px;
  transition: 0.3s;
}

.search:hover {
  background: #fff;
  border: solid 1px #9281ff;
  box-shadow: 0px 0px 8px rgba(146, 129, 255, 0.5);
  transition: 0.3s;
}

.search:focus {
  outline: none;
  background: #fff;
  border: solid 1px #9281ff;
  box-shadow: 0px 0px 8px rgba(146, 129, 255, 0.5);
  transition: 0.3s;
  padding: 0 16px;
}

.searchSubmit {
  display: none;
}

.genreBtn {
  background: #fff;
  border: 1px solid #c4c4c4;
  border-radius: 8px;
  color: #999;
  border-radius: 8px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 14px;
  height: 40px;
  padding: 0 16px;
  transition: 0.5s;
  min-width: 100%;
  max-width: 100%;
}

.genreBtn:hover {
  background: #fff;
  border: solid 1px #9281ff;
  box-shadow: 0px 0px 8px rgba(146, 129, 255, 0.5);
  transition: 0.3s;
}

.genreBtn:active {
  background: #fff;
  border: solid 1px #9281ff;
  box-shadow: 0px 0px 8px rgba(146, 129, 255, 0.5);
  transition: 0.3s;
}

.genreBtn:focus {
  outline: none;
  background: #fff;
  border: solid 1px #9281ff;
  box-shadow: 0px 0px 8px rgba(146, 129, 255, 0.5);
  transition: 0.3s;
}

.genreBtnActive {
  composes: genreBtn;
  color: #333;
}

.selectArea {
  position: relative;
}

.selectArea:after {
  content: "";
  display: block;
  background: url(./IconArrowDrop.svg);
  background-size: cover;
  width: 10px;
  height: 10px;
  position: absolute;
  top: 16px;
  right: 16px;
}

.select {
  -webkit-appearance: none;
  appearance: none;
  background: #fff;
  border: solid 1px #c4c4c4;
  border-radius: 8px;
  color: #999;
  cursor: pointer;
  width: 100%;
  height: 40px;
  padding: 0 16px;
  transition-property: border, box-shadow;
}

.select:focus {
  outline: none;
  background: #fff;
  border: solid 1px #9281ff;
  box-shadow: 0px 0px 8px rgba(146, 129, 255, 0.5);
  transition: 0.3s;
}

.select:hover {
  background: #fff;
  border: solid 1px #9281ff;
  box-shadow: 0px 0px 8px rgba(146, 129, 255, 0.5);
  transition: 0.3s;
}

.selectActive {
  composes: select;
  color: #333;
}

.playerBox {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 1080px) {
  .list {
    display: block;
    margin-top: 16px;
    padding: 0 16px 16px;
    box-sizing: border-box;
  }

  .list li {
    margin-top: 16px;
  }

  .listClosed {
    display: none;
    border-top: none;
  }

  .btnDropbox {
    background: #fff;
    border: 0;
    border-bottom: 1px solid #f1f1f1;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    width: 100%;
    height: 40px;
    color: #333;
  }

  .btnDropbox[data-open="false"] {
    border-bottom: 1px solid transparent;
  }

  .btnSearch {
    display: flex;
    align-items: center;
  }

  .btnSearch svg {
    margin-right: 4px;
  }

  .btnToggle {
    display: flex;
    align-items: center;
  }

  .btnToggle[data-open="true"] {
    transform: rotate(180deg);
  }

  .btnToggle svg {
    fill: #333;
    width: 20px;
    height: 20px;
  }

  .keywords .searchArea {
    display: flex;
  }

  .searchSubmit {
    display: block;
    background: #9281ff;
    border: none;
    border-radius: 8px;
    color: #fff;
    border-radius: 8px;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    height: 40px;
    margin-left: 8px;
    padding: 0 16px;
    transition: 0.5s;
  }

  .btn:hover {
    opacity: 0.5;
    transition: 0.5s;
  }

  .btn:active {
    opacity: 0.5;
    transition: 0.5s;
  }

  .selectArea:after {
    top: 16px;
  }
}
