.btn {
  background: #9281ff;
  border: 1px solid #9281ff;
  border-radius: 8px;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  height: 40px;
  margin: 0;
  padding: 2px 16px 0;
  transition: 0.2s;
  box-sizing: border-box;
  user-select: none;
}

.btn[data-secondary="true"] {
  background: #fff;
  color: #333;
  border-color: #c5c5c5;
}

.btn:disabled {
  background-color: #d3d3d3;
  border-color: #d3d3d3;
  color: #fff;
  cursor: unset;
}

.btn:disabled[data-secondary="true"] {
  background-color: #f0f0f0;
  border-color: #f0f0f0;
}

.btn:hover {
  opacity: 0.7;
}

.btn:hover[data-secondary="true"] {
  opacity: unset;
  background-color: #f5f5f5;
}

.btn:hover:disabled {
  opacity: unset;
}

.btn:hover:disabled[data-secondary="true"] {
  background-color: #f0f0f0;
}

.btn:active {
  opacity: 0.3;
}

.btn:active:disabled {
  opacity: 0.3;
}
