.container {
  box-sizing: border-box;
  width: 960px;
  margin: 0 auto;
}

.table {
  display: table;
  width: 100%;
  border-collapse: collapse;
}

.thead th {
  border-bottom: 1px solid #333;
  box-sizing: border-box;
  font-size: 10px;
  padding: 8px 0;
}

.theadItem {
  width: 48%;
  text-align: left;
}

.theadPublish,
.theadRange {
  text-align: left;
}

.theadPrice,
.theadFigures,
.theadSales {
  text-align: right;
}

.box td {
  border-bottom: 1px solid #eaeaea;
  box-sizing: border-box;
  font-size: 14px;
  padding: 24px 0;
}

.box:last-child td {
  border-bottom: none;
}

.box td.item {
  padding-right: 16px;
}

.genre {
  color: #9281ff;
  font-size: 12px;
  margin: 0;
}

.ttl {
  font-size: 16px;
  font-weight: normal;
  margin: 8px 0;
  line-height: 140%;
}

.url {
  font-size: 12px;
  margin: 0;
}

.url a {
  color: #999;
  text-decoration: none;
}

.url a:hover {
  text-decoration: underline;
}

.price,
.figures,
.sales {
  text-align: right;
  font-weight: bold;
}

.box td {
  border-bottom: 1px solid #eaeaea;
}

.box td.publish {
  padding-right: 16px;
  box-sizing: border-box;
}

.box td.price,
.box td.figures,
.box td.sales {
  padding-left: 16px;
  box-sizing: border-box;
}

.publish[data-publish-red="true"] {
  color: #eb5757;
}

@media screen and (max-width: 960px) {
  .container {
    width: 100%;
    padding: 0 64px;
  }
}

@media screen and (max-width: 768px) {
  .container {
    padding: 0;
  }

  .table {
    width: 100%;
  }

  .table tr {
    width: 100%;
    display: block;
    padding: 16px;
    box-sizing: border-box;
    border-bottom: 1px solid #eaeaea;
  }

  .table tr:last-child {
    border-bottom: none;
  }

  .table tr.thead {
    display: none;
  }

  .box td {
    width: 100%;
    text-align: right;
    display: flex;
    justify-content: space-between;
    padding: 16px 0;
    border-bottom: 1px dashed #eaeaea;
  }

  .table tr:last-child td {
    border-bottom: 1px dashed #eaeaea;
  }

  .table tr:last-child td:last-child {
    border: none;
  }

  .box td:last-child {
    border-bottom: none;
  }

  .box td:before {
    content: attr(data-label);
    color: #333;
    font-size: 10px;
    font-weight: bold;
  }

  .box td.item {
    display: block;
    text-align: left;
    padding-right: 0;
  }

  .box td.publish {
    padding-right: 0;
  }

  .box td.price,
  .box td.figures,
  .box td.sales {
    padding-left: 0;
  }

  .txt {
    padding: 0 16px;
  }
}
