.flex {
  display: flex;
  box-sizing: border-box;
  height: 100vh;
}

@media screen and (max-width: 768px) {
  .flex {
    display: block;
  }
}
