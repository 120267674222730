.container {
  background: #fff;
}

.ttl {
  border-bottom: 1px solid #eaeaea;
  font-size: 16px;
  margin: 0;
  padding: 16px;
  text-align: center;
}

.box {
  box-sizing: border-box;
  padding: 32px;
}

@media screen and (max-width: 768px) {
  .box {
    padding: 16px;
  }
}

.list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.item + .item {
  margin-top: 16px;
}

.name {
  font-size: 14px;
  margin: 0;
}

.button {
  background: none;
  border: none;
  color: #2f80ed;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: opacity 0.2s cubic-bezier(0.22, 1, 0.36, 1);
}

.button svg {
  margin-right: 4px;
}

@media (hover: hover) {
  .button:hover {
    opacity: 0.5;
    transition: opacity 0.2s cubic-bezier(0.22, 1, 0.36, 1);
  }
  .button:active {
    opacity: 0.2;
    transition: opacity 0.2s cubic-bezier(0.22, 1, 0.36, 1);
  }
}
