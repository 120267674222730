.bg {
  background: #fff;
  width: 100%;
  height: calc(100vh - 48px);
  position: fixed;
  z-index: 10001;
  overflow-y: scroll;
  box-shadow: 0px 0px 3.2px rgba(0, 0, 0, 0.037),
    0px 0px 7.6px rgba(0, 0, 0, 0.053), 0px 0px 14.4px rgba(0, 0, 0, 0.065),
    0px 0px 25.7px rgba(0, 0, 0, 0.077), 0px 0px 48px rgba(0, 0, 0, 0.093),
    0px 0px 115px rgba(0, 0, 0, 0.13);
  transition-duration: 0.3s;
  transition-property: top, opacity;
  transition-timing-function: easeOutQuart;
  top: 100%;
  opacity: 0;
}

.bg[data-open="true"] {
  top: 48px;
  opacity: 1;
}

.close {
  display: flex;
  align-items: center;
  position: fixed;
  top: 12px;
  right: 24px;
  color: #fff;
  cursor: pointer;
  font-weight: bold;
  font-size: 12px;
  transition: 0.5s;
  z-index: 10002;
}

.close svg {
  margin-left: 4px;
  fill: #fff;
}

.close[data-open="false"] {
  display: none;
}

.overlay {
  cursor: pointer;
  background: rgba(0, 0, 0, 0.7);
  opacity: 0;
  visibility: hidden;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  transition: opacity 0.3s ease;
}

.overlay:hover .close {
  opacity: 0.7;
  transition: 0.5s;
}

.overlay[data-open="true"] {
  opacity: 1;
  visibility: visible;
}

@media screen and (max-width: 768px) {
  .bg {
    height: 100vh;
  }
  .bg[data-open="true"] {
    top: 0;
  }

  .close {
    color: #333;
    top: 16px;
    right: 16px;
    z-index: 10002;
  }

  .close svg {
    margin-left: 4px;
    fill: #333;
  }
}
