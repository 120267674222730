.btn {
  border-radius: 8px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: bold;
  padding: 4px 8px;
  transition: opacity 0.4s cubic-bezier(0.22, 1, 0.36, 1);
  height: 32px;
}

@media (hover: hover) {
  .btn:hover {
    opacity: 0.5;
    transition: opacity 0.4s cubic-bezier(0.22, 1, 0.36, 1);
  }
  .btn:active {
    opacity: 0.2;
    transition: opacity 0.4s cubic-bezier(0.22, 1, 0.36, 1);
  }
}

.btn svg {
  margin-right: 0;
}

.btnShare {
  composes: btn;
  background: #fff;
  border: 1px solid #c4c4c4;
  border-radius: 8px;
  color: #333;
  width: 40px;
}
