.container {
  display: flex;
  justify-content: space-between;
  border-bottom: solid 1px #eaeaea;
  height: 64px;
  padding: 0 24px;
  position: sticky;
  width: 100%;
  box-sizing: border-box;
  top: 0;
  background: #fff;
  z-index: 9999;
}

.flex {
  display: flex;
  align-items: center;
}

.logo {
  font-size: 24px;
  transition: opacity 0.3s;
}

.logo a {
  color: #333;
  text-decoration: none;
}

.logo:hover {
  opacity: 0.5;
}

.copy {
  font-size: 12px;
  margin: 0 0 0 8px;
}

.btn {
  background: #9281ff;
  border: none;
  border-radius: 8px;
  color: #fff;
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
  padding: 0 16px;
  transition: opacity 0.3s;
  margin-left: 16px;
}

a.btn {
  text-decoration: none;
  line-height: 20px;
  padding: 10px 16px;
}

button.btn {
  height: 40px;
}

.btn:hover {
  opacity: 0.8;
}

.btn:active {
  opacity: 0.3;
}

.user {
  border: solid 1px #eaeaea;
  border-radius: 50px;
  cursor: pointer;
  margin-left: 16px;
  padding: 0;
  width: 40px;
  height: 40px;
  overflow: hidden;
  transition: opacity 0.3s;
}

.user:hover {
  opacity: 0.8;
}

.user:active {
  opacity: 0.3;
}

.user img {
  width: 100%;
}

.userMenu {
  background: #fff;
  border-radius: 8px;
  border: 1px solid #eaeaea;
  width: 240px;
  position: absolute;
  top: 64px;
  right: 24px;
  box-shadow: 0px 0px 1.7px rgba(0, 0, 0, 0.04),
    0px 0px 5.6px rgba(0, 0, 0, 0.06), 0px 0px 25px rgba(0, 0, 0, 0.1);
  z-index: 10;
}

.userMenu::before {
  content: "";
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  right: 12px;
  top: -12px;
  border-right: 8px solid transparent;
  border-bottom: 12px solid #eaeaea;
  border-left: 8px solid transparent;
}

/* beforeで本体の三角を表現 */
.userMenu::after {
  content: "";
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  right: 12px;
  top: -9px;
  border-right: 8px solid transparent;
  border-bottom: 12px solid #fff;
  border-left: 8px solid transparent;
}

.userMenuContent {
  font-size: 14px;
  list-style: none;
  margin: 0 24px;
  padding: 16px 0 24px;
}

.userMenuContent li {
  margin-top: 8px;
}

.userMenuContent a {
  color: #333;
  text-decoration: none;
}

.userMenuContent a:hover {
  color: #9281ff;
}

.userMenuContentTop {
  composes: userMenuContent;
  border-bottom: 1px solid #eaeaea;
}

.userMenuContentBottom {
  composes: userMenuContent;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 5;
}

@media screen and (max-width: 768px) {
  .container {
    display: none;
  }
}
