.container {
  padding-top: 64px;
}

.main {
  width: 640px;
  margin: 64px auto 0;
  box-sizing: border-box;
  text-align: center;
}

.main img {
  width: 100%;
}

.ttl {
  font-size: 32px;
  text-align: center;
}

.txt {
  font-size: 16px;
  text-align: center;
  line-height: 1.8;
  margin-top: 32px;
}

.btn {
  background: #9281ff;
  border: none;
  color: #fff;
  font-size: 14px;
  margin-top: 24px;
  border-radius: 8px;
  cursor: pointer;
  font-weight: bold;
  padding: 2px 16px 0;
  transition: 0.5s;
}

a.btn {
  display: inline-block;
  text-decoration: none;
  line-height: 20px;
  padding: 10px 16px;
}

.btn:hover {
  opacity: 0.7;
  transition: 0.5s;
}

@media screen and (max-width: 768px) {
  .container {
    padding-top: 32px;
  }

  .main {
    width: 100%;
    margin: 32px auto 0;
    padding: 0 16px;
    box-sizing: border-box;
  }

  .txt {
    text-align: left;
  }
}
