.button {
  background: none;
  border: none;
  color: #2f80ed;
  border-radius: 8px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  width: 100%;
  margin: 16px 0 0;
  transition: opacity 0.2s cubic-bezier(0.22, 1, 0.36, 1);
}

.button:disabled {
  background: #f2f2f2;
  color: #c4c4c4;
  cursor: unset;
}

.button svg {
  margin-right: 4px;
}

@media (hover: hover) {
  .button:not(:disabled):hover {
    opacity: 0.5;
  }
  .button:not(:disabled):active {
    opacity: 0.2;
  }
}
