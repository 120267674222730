.container {
  box-sizing: border-box;
  width: 100%;
  margin: 64px auto 0;
}

.publish {
  font-size: 14px;
  list-style: none;
  padding: 0;

  display: flex;
  align-items: center;
}

.limited,
.private {
  border: 1px solid #ff2424;
  border-radius: 4px;
  color: #ff2424;
  padding: 4px;
}

@media screen and (max-width: 768px) {
  .container {
    margin: 56px auto 0;
    padding: 0 16px;
  }
}

.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.writer {
  font-size: 14px;
  margin: 0;
}

.writer a {
  color: #2f80ed;
  text-decoration: underline;
}

.menu {
  background: none;
  border: none;
  cursor: pointer;
  margin: 0;
  padding: 0;
}

.img {
  margin: 24px 0 0;
  padding-top: calc(56.25% - 2px);
  position: relative;
  overflow: hidden;
  border: 1px solid #eaeaea;
}

.img img {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.genre {
  font-size: 12px;
  margin: 24px 0 0;
}

.genre a {
  color: #333;
  text-decoration: none;
}

.genre a:hover {
  text-decoration: underline;
}

.ttl {
  font-size: 18px;
  font-weight: normal;
  line-height: 140%;
  margin: 8px 0 0;
}

.summary {
  color: #999;
  font-size: 12px;
  display: flex;
  align-items: baseline;
  list-style: none;
  margin: 8px 0 0;
  padding: 0;
}

.summary li {
  margin-right: 8px;
}

.summary span {
  color: #9281ff;
  font-size: 16px;
  font-weight: bold;
  margin-right: 4px;
}

@media screen and (max-width: 768px) {
  .summary {
    display: block;
  }

  .summary li {
    margin-top: 4px;
    margin-right: 0;
  }
}

.tag {
  display: flex;
  margin: 16px 0 0;
  padding: 0;
  list-style: none;
  flex-wrap: wrap;
}

.tag li {
  margin-right: 4px;
}

.tag a {
  color: #9281ff;
  font-size: 12px;
  text-decoration: none;
}

.description {
  font-size: 14px;
  line-height: 160%;
  margin: 16px 0 0;
}

.description[data-open="true"] {
  display: block;
  overflow: auto;
  white-space: pre-wrap;
}

.description[data-open="false"] {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.more {
  background: none;
  border: none;
  color: #999;
  cursor: pointer;
  font-size: 12px;
  margin: 4px 0 0;
  padding: 0;
}
