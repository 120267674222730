.container {
  background: #fff;
}

.ttl {
  border-bottom: 1px solid #eaeaea;
  font-size: 16px;
  margin: 0;
  padding: 16px;
  text-align: center;
}

.body {
  margin: 0;
  padding: 0 32px 32px;
}

.projectName {
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  margin: 32px 0 16px 0;
}

.notation {
  color: #999;
  font-size: 12px;
  margin-top: 24px;
}

.notation h4 {
  font-weight: bold;
  margin: 0;
  line-height: 1.6;
}

.notation p {
  margin: 0;
  line-height: 1.6;
}

.shareBooklet {
  display: flex;
  align-items: center;
}

.shareBooklet + .shareBooklet {
  margin-top: 16px;
}

.shareBooklet svg {
  margin-left: -8px;
}

.shareBooklet .bookletName {
  flex-grow: 1;
  flex-shrink: 1;
}

.btn {
  background: #fff;
  border: 1px solid #c4c4c4;
  color: #333;
  border-radius: 4px;
  box-sizing: border-box;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  height: 32px;
  margin-left: 16px;
  padding: 0 16px;
  padding-top: 2px;
  transition: 0.1s;
  min-width: 4rem;
}

.btn:hover,
.btn:active {
  background: #9281ff;
  border: 1px solid #9281ff;
  color: #fff;
}

@media screen and (max-width: 768px) {
  .container {
    height: auto;
    padding-bottom: 64px;
  }

  .body {
    margin: 32px 0 0;
    padding: 0 16px;
  }
}

@media screen and (max-width: 414px) {
  .container {
    padding-bottom: 32px;
  }

  .btn {
    margin-left: 0;
  }
}
