.container {
  background: #fff;
}

.ttl {
  border-bottom: 1px solid #eaeaea;
  font-size: 16px;
  margin: 0;
  padding: 16px;
  text-align: center;
}

.box {
  box-sizing: border-box;
  padding: 32px;
}

.flex {
  display: flex;
}

.left {
  width: 120px;
}

.right {
  margin-left: 16px;
  width: calc(100% - 136px);
}

@media screen and (max-width: 768px) {
  .box {
    padding: 16px;
  }

  .flex {
    display: block;
  }

  .left {
    width: 100%;
  }

  .right {
    width: 100%;
    margin: 16px 0 0;
  }
}

.img {
  margin: 0;
  padding-top: calc(56.25% - 2px);
  position: relative;
  overflow: hidden;
  border: 1px solid #eaeaea;
  border-radius: 4px;
}

.img img {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.imgBlank {
  composes: img;
  background: #f1f1f1;
}

.genre {
  color: #9281ff;
  font-size: 12px;
  margin: 0;
}

.title {
  font-size: 16px;
  line-height: 140%;
  margin: 8px 0 0;
}

.date {
  color: #999;
  font-size: 14px;
  margin: 8px 0 0;
}

.label {
  font-size: 12px;
  font-weight: bold;
  margin: 24px 0 0;
  display: block;
}

.url {
  font-size: 14px;
  border: 1px solid #eaeaea;
  border-radius: 8px;
  margin: 8px 0 0;
  padding: 8px 16px;
  word-break: break-all;
}

.button {
  background: #9281ff;
  border: none;
  border-radius: 8px;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  width: 100%;
  height: 40px;
  margin-top: 24px;
  padding: 2px 16px 0;
  transition: 0.5s;
}

@media (hover: hover) {
  .button:hover {
    opacity: 0.5;
    transition: opacity 0.4s cubic-bezier(0.22, 1, 0.36, 1);
  }
  .button:active {
    opacity: 0.2;
    transition: opacity 0.4s cubic-bezier(0.22, 1, 0.36, 1);
  }
}
