@import-normalize;
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap");

body {
  margin: 0;
  padding: 0 !important;
  color: #333;
  font-family: "Helvetica Neue", "Noto Sans JP", -apple-system, Arial,
    "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a:focus {
  outline: none;
}
