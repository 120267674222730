.loadingImage {
  width: 100%;
  padding: 16px;
  box-sizing: border-box;

  background-color: #f1f1f1;
  border: 1px solid #eaeaea;
  border-radius: 2px;
  display: flex;
  justify-content: center;
}
