.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  border-top: 1px solid #eaeaea;
  box-sizing: border-box;
  padding: 8px 24px;
  width: calc(100% - 240px);
  position: fixed;
  left: 240px;
  bottom: 0;
  z-index: 999;
}

.container[data-preview="true"] {
  width: 100vw;
  left: 0;
  z-index: 10000;
}

.list {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 90%;
}

.list li + li {
  margin-left: 8px;
}

.log {
  color: #999;
  font-size: 12px;
  margin: 0;
  line-height: 140%;
  width: calc(30% + 16px);
}

.savedIcon {
  margin-left: 4px;
  width: 12px;
  height: 12px;
  vertical-align: top;
  margin-top: 2px;
  animation: loader1 300ms 1 forwards;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
}
@keyframes saved {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.4);
  }
  100% {
    transform: scale(1);
  }
}

.loader {
  margin-top: 8px;
  vertical-align: top;
  display: inline-block;
  position: relative;
  width: 16px;
}
.loader div {
  margin-top: -1px;
  position: absolute;
  top: 50%;
  width: 2px;
  height: 2px;
  border-radius: 50%;
  background: #999;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.loader div:nth-child(1) {
  left: 8px;
  animation: loader1 0.6s infinite;
}
.loader div:nth-child(2) {
  left: 8px;
  animation: loader2 0.6s infinite;
}
.loader div:nth-child(3) {
  left: 12px;
  animation: loader2 0.6s infinite;
}
.loader div:nth-child(4) {
  left: 16px;
  animation: loader3 0.6s infinite;
}

@keyframes loader1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes loader3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes loader2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(4px, 0);
  }
}

.btn {
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
  height: 32px;
  padding: 2px 16px 0;
  transition: 0.5s;
}

.btn:hover {
  opacity: 0.7;
  transition: 0.5s;
}

.btnActive {
  composes: btn;
  background: #f1f1f1;
  color: #333;
}

.btnInActive {
  composes: btn;
  background: #fff;
  color: #999;
}

.count {
  color: #999;
  font-size: 12px;
  margin: 0;
  text-align: right;
  width: 20%;
}

.count span {
  color: #333;
  font-size: 14px;
}

@media screen and (max-width: 768px) {
  .container {
    width: 100%;
    padding: 8px 16px;
    left: 0;
  }

  .log {
    font-size: 10px;
    width: 50%;
  }

  .list {
    width: 50%;
    justify-content: flex-end;
  }

  .count {
    display: none;
  }
}
