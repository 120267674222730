.container {
  box-sizing: border-box;
  width: calc(100% - 240px);
  height: auto;
  position: relative;
  left: 240px;
}

@media screen and (max-width: 768px) {
  .container {
    left: 0;
    width: 100%;
  }
}
