.container {
  box-sizing: border-box;
  width: 960px;
  margin: 32px auto 0;
}

.table {
  display: table;
  width: 100%;
  border: 1px solid #c4c4c4 !important;
  border-collapse: collapse;
}

.tr {
  display: table-row;
}

.tr th {
  background: #f8f8f8;
  border-right: 1px solid #c4c4c4 !important;
  box-sizing: border-box;
  color: #333;
  font-size: 10px;
  padding: 8px;
}

.tr th:last-child {
  border-right: none;
}

.tr td {
  font-size: 14px;
  border-top: 1px solid #c4c4c4 !important;
  border-right: 1px solid #c4c4c4 !important;
  box-sizing: border-box;
  color: #333;
  line-height: 140%;
  padding: 8px;
}

.status {
  text-align: center;
}

.statusRed {
  composes: status;
  color: #eb5757;
}

.ttl {
  width: 300px;
}

.number {
  font-family: monospace;
}

.number,
.other {
  text-align: center;
}

.price,
.fee,
.spll,
.profit {
  text-align: right;
}

.btn {
  background: #fff;
  border: 1px solid #c4c4c4;
  border-radius: 8px;
  color: #333;
  cursor: pointer;
  font-weight: bold;
  padding: 2px 8px 0;
  transition: 0.2s;

  white-space: nowrap;
}

.btn:hover {
  border: 1px solid #9281ff;
  color: #9281ff;
  transition: 0.2s;
}

.btn:active {
  opacity: 0.3;
}

.showAllBtn {
  display: flex;
  margin-top: 16px;
  justify-content: center;
}

@media screen and (max-width: 960px) {
  .container {
    box-sizing: border-box;
    width: 100%;
    margin: 0 auto;
    padding: 0 64px;
  }

  .tr th:nth-child(n + 6):not(:nth-child(n + 9)) {
    display: none;
  }

  .tr td:nth-child(n + 6):not(:nth-child(n + 9)) {
    display: none;
  }

  td {
    font-size: 12px;
  }

  .btn {
    font-size: 12px;
  }
}

@media screen and (max-width: 768px) {
  .container {
    width: 100%;
    margin-top: 32px;
    padding: 0 16px;
  }

  .tr th:nth-child(n + 4):not(:nth-child(n + 9)) {
    display: none;
  }

  .tr td:nth-child(n + 4):not(:nth-child(n + 9)) {
    display: none;
  }

  .tr th,
  .tr td {
    font-size: 10px;
    padding: 4px;
  }

  .ttl {
    width: 46%;
  }

  .btn {
    font-size: 10px;
  }
}
