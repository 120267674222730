.editArea {
  padding: 32px;
  /* box-shadow: 0 0 24px rgba(0, 0, 0, 0.1); */
  max-width: 700px;
  margin: 115px auto 0;
  padding-bottom: 80px;
  /* box-sizing: border-box; */
}

/* .editArea div[class="DraftEditor-root"] {
  outline: 1px solid red;
} */

.libretto .input {
  font-size: 48px;
  margin: 0;
  border: none;
  width: 100%;
  box-sizing: border-box;
  line-height: 140%;
}

.libretto .input::placeholder {
  color: #999;
}

.libretto .input:focus {
  outline: none;
}

.editArea figure {
  margin: 16px 0;
}

.editArea img {
  max-width: 100%;
}

.editArea h1[data-block="true"] {
  font-size: 32px;
  font-weight: normal;
  line-height: 160%;
  margin: 2.8rem 0 0;
  word-break: break-word;
  margin-bottom: 16px;
  padding-bottom: 8px;
  border-bottom: 1px solid #e2e2e2;
}

.editArea h2[data-block="true"] {
  font-size: 24px;
  font-weight: normal;
  line-height: 160%;
  margin: 2.4rem 0 0;
  word-break: break-word;
}

.editArea h3[data-block="true"] {
  font-size: 20px;
  font-weight: normal;
  line-height: 160%;
  margin: 2rem 0 0;
  word-break: break-word;
}

.editArea div[class="notes"] {
  margin: 1rem 0;
  border-radius: 8px;
  padding: 24px;
  background: #f8f8f8;
  word-break: break-word;
}

.editArea div[class="notes"] div[data-block="true"] {
  margin: 0;
  line-height: 160%;
  font-size: 12px;
  color: #999;
}

.editArea div[data-block="true"] a {
  color: #2f80ed;
}

.editArea div[data-block="true"] {
  font-size: 16px;
  line-height: 180%;
  word-break: break-word;
  margin: 4px 0;
}

.editArea .public-DraftEditorPlaceholder-root {
  font-size: 16px;
  line-height: 200%;
}

@media screen and (max-width: 768px) {
  .editArea {
    margin: 100px auto 0;
    padding-right: 16px;
    padding-left: 16px;
    padding-bottom: 160px;
  }

  .editArea h1[data-block="true"] {
    font-size: 24px;
    line-height: 140%;
    padding-bottom: 4px;
    margin-bottom: 8px;
  }

  .editArea h2[data-block="true"] {
    font-size: 18px;
    line-height: 140%;
  }

  .editArea h3[data-block="true"] {
    font-size: 16px;
    line-height: 140%;
  }

  .editArea div[data-block="true"] {
    font-size: 14px;
    line-height: 160%;
  }

  .editArea div[class="notes"] {
    padding: 16px;
  }

  .libretto .input {
    font-size: 32px;
    line-height: 130%;
  }
}
