.box {
  box-sizing: border-box;
  padding: 24px;
  padding-top: 8px;
}

.label {
  display: block;
  font-size: 12px;
  font-weight: bold;
  margin-top: 32px;
  margin-bottom: 8px;
}

.input {
  font-size: 14px;
  border: solid 1px #c4c4c4;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 0 16px;
  width: 100%;
  height: 40px;
}

.input:hover {
  background: #fff;
  border: solid 1px #9281ff;
  box-shadow: 0px 0px 8px rgba(146, 129, 255, 0.5);
  transition: 0.3s;
}

.input:focus {
  outline: none;
  background: #fff;
  border: solid 1px #9281ff;
  box-shadow: 0px 0px 8px rgba(146, 129, 255, 0.5);
  transition: 0.3s;
}

.price {
  position: relative;
}

.price:before {
  display: block;
  content: "-¥";
  color: #999;
  font-size: 20px;
  position: absolute;
  top: 8px;
  left: 14px;
}

.inputPrice {
  composes: input;
  width: 250px;
  padding-left: 40px;
}

.submitArea {
  margin-top: 32px;
  display: flex;
  justify-content: center;
}

.breakdown {
  display: flex;
  font-size: 12px;
  margin: 8px 0 0;
}

.breakdown dt {
  margin: 0;
  padding-left: 1rem;
  width: 100px;
}

.breakdown dd {
  margin: 0;
}

.errorMessage {
  font-size: 16px;
  color: #eb5757;
  margin: 0;
  padding: 8px 0 0 1rem;
}

.note {
  color: #999;
  font-size: 12px;
  line-height: 140%;
  text-indent: -1em;
  margin: 8px 0 0;
  padding-left: 2rem;
  list-style: none;
}

.radioArea {
  display: flex;
  align-items: center;
  font-size: 14px;
  border: solid 1px #c4c4c4;
  border-radius: 8px;
  box-sizing: border-box;
  margin-top: 8px;
  padding: 0 16px;
  width: 250px;
  height: 40px;
  cursor: pointer;
}

.radioArea:hover {
  background: #fff;
  border: solid 1px #9281ff;
  box-shadow: 0px 0px 8px rgba(146, 129, 255, 0.5);
  transition: 0.3s;
}

.radio {
  margin-right: 4px;
}

@media screen and (max-width: 768px) {
  .container {
    height: auto;
    padding-bottom: 64px;
  }

  .box {
    width: 100%;
    margin-top: 32px;
    padding: 0 16px;
  }

  .selectArea,
  .inputPrice,
  .radioArea {
    width: 100%;
  }
}
