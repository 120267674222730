.btn {
  background: #9281ff;
  border: 1px solid #9281ff;
  border-radius: 8px;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  height: 40px;
  margin: 0;
  padding: 2px 16px 0;
  transition: 0.2s;
  box-sizing: border-box;
  user-select: none;
}

.btn[data-secondary="true"] {
  background: #fff;
  color: #333;
  border-color: #c5c5c5;
}

.btn:disabled {
  background: #d3d3d3;
  border-color: #d3d3d3;
  color: #fff;
  cursor: unset;
}

.btn[data-full-width="true"],
.loading[data-full-width="true"] {
  width: 100%;
}

.btn:hover {
  opacity: 0.7;
}

.btn:hover:disabled {
  opacity: 1;
}

.btn:active {
  opacity: 0.3;
}

.btn:hover[data-secondary="true"] {
  opacity: unset;
  background-color: #f5f5f5;
}

.btn:active {
  opacity: unset;
  background-color: #f5f5f5;
}

/* Loader */
.loading {
  display: inline-flex;
  background: #ccc;
  border: none;
  border-radius: 8px;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  height: 40px;
  margin: 0;
  padding: 2px 16px 0;
  transition: 0.5s;

  justify-content: center;
  align-items: center;
  box-sizing: border-box;

  user-select: none;
}

.loader {
  margin-top: 8px;
  margin-left: -4px;
  vertical-align: top;
  display: inline-block;
  position: relative;
  width: 16px;
}
.loader div {
  margin-top: -1px;
  position: absolute;
  top: 50%;
  width: 2px;
  height: 2px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.loader div:nth-child(1) {
  left: 8px;
  animation: loader1 0.6s infinite;
}
.loader div:nth-child(2) {
  left: 8px;
  animation: loader2 0.6s infinite;
}
.loader div:nth-child(3) {
  left: 12px;
  animation: loader2 0.6s infinite;
}
.loader div:nth-child(4) {
  left: 16px;
  animation: loader3 0.6s infinite;
}

@keyframes loader1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes loader3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes loader2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(4px, 0);
  }
}
