.Sidebar__root {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 240px;
  box-sizing: border-box;
  z-index: 9999;

  transition: left 0.2s ease-out;

  border-right: 1px solid #e0e0e0;
  background-color: #fff;
  color: #222;

  display: flex;
  flex-direction: column;

  overflow: hidden;
}

.Sidebar__root[data-open="false"] {
  left: -240px;
}

.Sidebar__overlay {
  display: none;
}

@media screen and (max-width: 1120px) {
  .Sidebar__root {
    left: -240px;
  }

  .Sidebar__root[data-open-mobile="true"] {
    left: 0;
  }

  .Sidebar__overlay[data-open-mobile="true"] {
    display: block;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9998;
    background-color: #333;
    opacity: 0.5;

    cursor: pointer;
  }
}

.Sidebar__title {
  height: 40px;
  font-size: 18px;
  line-height: 40px;
  flex: 0 0;

  display: flex;
  align-items: center;

  border-bottom: 1px solid #e0e0e0;
}

.Sidebar__projectName {
  color: #9281ff;
  font-size: 15px;
  font-weight: bold;
  margin-left: 16px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.Sidebar__closeButton {
  cursor: pointer;
  margin-left: auto;
  fill: #999;
  flex-shrink: 0;

  padding: 4px;
  margin-right: 4px;
}

@media (hover: hover) {
  .Sidebar__closeButton:hover {
    background-color: #f5f5f5;
    fill: #333;
  }
}

@media (hover: none) {
  .Sidebar__closeButton:active {
    background-color: #f5f5f5;
    fill: #333;
  }
}

.Sidebar__index {
  padding-top: 16px;
  flex: 1 1;
  height: 100%;

  overflow-x: hidden;
  overflow-y: auto;
}

.Sidebar__bookletsHeader {
  padding: 0 18px;
  font-size: 14px;
  font-weight: bold;
  line-height: 2;
  user-select: none;
}

.Sidebar__button {
  font-size: 14px;
  line-height: 2;
  padding: 4px 8px;
  margin: 8px;

  display: flex;
  align-items: center;

  cursor: pointer;
  user-select: none;
}

.Sidebar__button svg {
  margin-right: 6px;
}

@media (hover: hover) {
  .Sidebar__button:hover {
    background-color: #f5f5f5;
  }
}

@media (hover: none) {
  .Sidebar__button:active {
    background-color: #f5f5f5;
  }
}

.Sidebar__settingsArea {
  flex: 0 0;
  border-top: 1px solid #e0e0e0;
}

.Sidebar__headings {
  list-style: none;
  padding: 0;
  margin: 7px;
}

/* IndexBookletRow */

.Sidebar__IndexBookletRow_heading {
  color: #999;

  margin-top: 8px;
  padding: 0;
  padding-left: 4px;
  cursor: pointer;

  display: flex;
  align-items: center;
  user-select: none;

  position: relative;
}

@media (hover: hover) {
  .Sidebar__IndexBookletRow_heading:hover {
    background-color: #f5f5f5;
  }
}

@media (hover: none) {
  .Sidebar__IndexBookletRow_heading:active {
    background-color: #f5f5f5;
  }
}

@media screen and (max-width: 414px) {
  .Sidebar__IndexBookletRow_heading {
    margin-top: 6px;
  }
}

.Sidebar__IndexBookletRow_heading[data-active="true"] {
  color: #333;
  background-color: #eee;
}

.Sidebar__IndexBookletRow_headingOpen {
  padding: 4px;
}

.Sidebar__IndexBookletRow_headingName {
  display: inline-block;
  font-size: 14px;
  line-height: 2;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  width: calc(100% - 32px);
}

.Sidebar__IndexBookletRow_buttons {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 4px;

  background-color: inherit;
  display: flex;
  align-items: center;
}

.Sidebar__IndexBookletRow_button {
  display: none;
  margin-left: 4px;
  fill: #555;
}

.Sidebar__IndexBookletRow_button:active {
  background-color: #e0e0e0;
  fill: #111;
}

@media (hover: hover) {
  .Sidebar__IndexBookletRow_button:hover {
    background-color: #e0e0e0;
    fill: #111;
  }

  .Sidebar__IndexBookletRow_heading:hover .Sidebar__IndexBookletRow_button {
    display: inherit;
  }
}

@media (hover: none) {
  .Sidebar__IndexBookletRow_button:active {
    background-color: #e0e0e0;
    fill: #111;
  }

  .Sidebar__IndexBookletRow_button {
    display: inherit;
  }
}

/* IndexHeading */

.Sidebar__BookletHeadings_root {
  color: #999;
  list-style: none;
  padding: 0;
}

.Sidebar__IndexBookletRow_heading[data-active="true"]
  + .Sidebar__BookletHeadings_root {
  color: #333;
}

.Sidebar__IndexHeading_heading {
  font-size: 14px;
  cursor: pointer;
  line-height: 2;

  padding: 0;
  padding-left: 32px;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media (hover: hover) {
  .Sidebar__IndexHeading_heading:hover {
    background-color: #f5f5f5;
  }
}

@media (hover: none) {
  .Sidebar__IndexHeading_heading:active {
    background-color: #f5f5f5;
  }
}

.Sidebar__IndexHeading_heading[data-indent="true"] {
  padding-left: 48px;
}
