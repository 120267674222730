.container {
  box-sizing: border-box;
  width: 100%;
}

@media screen and (max-width: 767px) {
  .container {
    width: 100%;
  }
}
