.pager {
  display: flex;
  align-items: center;
  margin-bottom: 80px;
}

.pager .spacer {
  min-width: 16px;
  width: 1%;
  flex-grow: 2;
  flex-shrink: 2;
}

.pager .link {
  display: inline-flex;
  align-items: center;
  max-width: 60%;
  cursor: pointer;
}

@media print {
  .pager {
    display: none;
  }
}
