.container {
  width: 100%;
  margin-top: 48px;
  padding: 0 64px;
  box-sizing: border-box;
}

@media screen and (max-width: 960px) {
  .container {
    width: 100%;
    padding: 0 32px;
  }
}

@media screen and (max-width: 767px) {
  .container {
    padding: 0;
    box-sizing: border-box;
  }
}

.officialArea {
  background-color: #f8f8f8;
  border-radius: 8px;
  padding: 32px;
}

.slideHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.slideTtl {
  margin: 0;
}

.slideAction {
  display: flex;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
}

.slideBtn {
  border: none;
  background: none;
}

.slideBtn svg {
  fill: #333;
  transition: 0.1s;
  cursor: pointer;
}

.slideBtnPrev,
.slideBtnNext {
  composes: slideBtn;
}

.slideBtnPrev[data-end="true"] svg,
.slideBtnNext[data-end="true"] svg {
  fill: #c4c4c4;
  cursor: inherit;
}

.slideBtn:hover svg {
  fill: #9281ff;
  transition: 0.3s;
}

.slideBtnPrev[data-end="true"]:hover svg,
.slideBtnNext[data-end="true"]:hover svg {
  fill: #c4c4c4;
}

.list {
  display: -ms-flexbox;
  display: flex;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 24px;
  margin: 0;
  padding: 0;
  overflow-x: scroll;
  -ms-overflow-style: none;

  scroll-snap-type: x mandatory;
}

.list::-webkit-scrollbar {
  display: none;
}

.cards {
  background-color: #fff;
  border: 1px solid #eaeaea;
  border-radius: 8px;
  box-sizing: border-box;
  list-style: none;
  overflow: hidden;
  cursor: pointer;
  position: relative;
  transition: 0.2s;
  width: 100%;
  min-width: 280px;
  max-width: 280px;

  scroll-snap-align: start;
}

.cards:hover {
  border: 1px solid #9281ff;
  box-shadow: 0px 0px 12px rgba(146, 129, 255, 0.5);
}

.cards:nth-child(4n + 1) {
  margin-left: 0;
}

.cards a {
  text-decoration: unset;
  color: #333;
}

.thumnail {
  width: 100%;
  padding-top: 56.25%;
  position: relative;
  overflow: hidden;
}

.thumnail img {
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
}

.ttl {
  font-size: 16px;
  font-weight: normal;
  margin: 12px 0;
  padding: 0 16px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-align: justify;
}

.pay {
  border-top: 1px dashed #f1f1f1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style: none;
  margin-top: 8px;
  padding: 6px 16px 4px;
}

.status {
  font-size: 12px;
  color: #c4c4c4;
  display: flex;
  justify-content: center;
  align-items: center;
}

.status svg {
  margin-right: 2px;
}

.price {
  font-size: 14px;
}

@media screen and (max-width: 768px) {
  .list {
    display: -ms-flexbox;
    display: flex;
    grid-gap: 16px;
    padding: 0 16px 24px;
    overflow-x: scroll;
  }

  .cards {
    padding-bottom: 10px;
    width: 100%;
    min-width: 280px;
  }

  .category {
    padding: 0 8px;
    font-size: 10px;
  }

  .ttl {
    font-size: 14px;
    padding: 0 8px;
  }

  .pay {
    padding: 6px 16px;
  }

  .officialArea {
    padding: 0;
    border-radius: 0;
  }

  .slideHeader {
    margin-bottom: 16px;
  }

  .slideTtl {
    font-size: 18px;
    margin: 24px 0 0;
    padding: 0 16px;
  }

  .slideAction {
    display: none;
  }
}
