.container {
  margin: 64px auto 0;
  box-sizing: border-box;
  width: 640px;
}

.header {
  font-weight: 700;
  font-size: 18px;
  line-height: 160%;
  text-align: center;

  margin: 0;
  margin-bottom: 32px;
}

.card {
  width: 100%;
  max-width: 480px;

  overflow: hidden;
  box-sizing: border-box;
  border: none;
  border-radius: 8px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);

  margin: 0 auto;
  margin-bottom: 32px;
}

.thumbnail {
  width: 100%;
  padding-top: 56.25%;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
}

.thumbnail img {
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
}

.blankThumbnail {
  width: 100%;
  padding-top: 56.25%;
  box-sizing: border-box;
  background-color: #f1f1f1;
}

.cardBody {
  padding: 24px;
}

.categoryName {
  font-size: 12px;
  line-height: 17px;
  color: #9281ff;
}

.projectName {
  font-size: 18px;
  line-height: 160%;
  margin: 0;
}

.receiveBtn {
  display: flex;
  justify-content: center;
}

.btn {
  background: #9281ff;
  border: 1px solid #9281ff;
  border-radius: 8px;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  height: 40px;
  margin: 0;
  padding: 2px 16px 0;
  transition: 0.2s;
  box-sizing: border-box;
  user-select: none;

  text-decoration: none;
  display: inline-flex;
  align-items: center;
}

.btn:hover {
  opacity: 0.7;
}

.btn:active {
  opacity: 0.3;
}

@media screen and (max-width: 768px) {
  .container {
    margin-top: 48px;
    padding: 0 16px;
    width: 100%;
  }
}
