.container {
  box-sizing: border-box;
  margin: 48px auto 80px;
  padding: 0 64px;
}

@media screen and (max-width: 768px) {
  .container {
    margin: 32px auto;
    padding: 0;
  }
}
