.selectArea {
  position: relative;
  width: 320px;
}

.selectArea[data-fullwidth="true"] {
  width: 100%;
}

.selectArea:after {
  content: "";
  display: block;
  background: url(./IconArrowDrop.svg);
  background-size: cover;
  width: 10px;
  height: 10px;
  position: absolute;
  top: 18px;
  right: 16px;
}

.selectArea select {
  -webkit-appearance: none;
  appearance: none;
  background: #fff;
  border: solid 1px #c4c4c4;
  border-radius: 8px;
  color: #999;
  cursor: pointer;
  width: 100%;
  height: 40px;
  padding: 0 16px;
}

.selectArea select.active {
  color: #333;
}

.selectArea select:focus {
  outline: none;
  background: #fff;
  border: solid 1px #9281ff;
  box-shadow: 0px 0px 8px rgba(146, 129, 255, 0.5);
  transition: 0.3s;
}

.selectArea select:hover {
  background: #fff;
  border: solid 1px #9281ff;
  box-shadow: 0px 0px 8px rgba(146, 129, 255, 0.5);
  transition: 0.3s;
}

@media screen and (max-width: 768px) {
  .selectArea {
    width: 100%;
  }
}
