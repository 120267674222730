.img {
  cursor: pointer;
  background: #f8f8f8;
  border: 1px dashed #c4c4c4;
  border-radius: 8px;
  box-sizing: border-box;
  width: 320px;
  height: 180px;
  text-align: center;
  margin: 0 auto;
  transition: 0.3s;
  overflow-y: hidden;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.img:hover,
.thumbnailWrap:hover {
  background: #fff;
  border: solid 1px #9281ff;
  box-shadow: 0px 0px 8px rgba(146, 129, 255, 0.5);
  transition: 0.3s;
}

.thumbnailWrap {
  cursor: pointer;
  background: #f8f8f8;
  border: 1px solid transparent;
  border-radius: 8px;
  box-sizing: border-box;
  width: 320px;
  height: 180px;
  margin: 0 auto;
  text-align: center;
  overflow-y: hidden;
  position: relative;
}

.thumbnail {
  cursor: pointer;
  width: 100%;
  transition: 0.3s;
}

.thumbnailDelete {
  background: rgba(0, 0, 0, 1);
  border: none;
  width: 24px;
  height: 24px;
  display: none;
  align-items: center;
  position: absolute;
  top: 4px;
  right: 4px;
  z-index: 10;
  cursor: pointer;
  opacity: 0.4;
}

.thumbnailDelete:hover {
  opacity: 1;
}

.thumbnailWrap:hover .thumbnailDelete {
  display: flex;
}

.note {
  color: #999;
  font-size: 12px;
  margin: 8px 0 0 0;
  padding-left: 0.7rem;
  text-indent: -0.7rem;
}

.note span {
  font-size: 12px;
}

@media screen and (max-width: 768px) {
  .thumbnailWrap,
  .img {
    width: 100%;
    height: auto;
    aspect-ratio: 320 / 180;
  }

  .note {
    color: #999;
    font-size: 12px;
    margin: 8px 0 0 0;
  }
}
