.container {
  width: 100%;
  max-width: 1500px;
  margin: 0 auto;
  margin-top: 32px;
  padding: 0 64px;
  box-sizing: border-box;
}

@media screen and (max-width: 767px) {
  .container {
    padding: 0 16px;
  }
}

.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
}

.category {
  font-size: 18px;
  margin: 0;
}

.buttonPC {
  border: 0;
  background: none;
  color: #9281ff;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.4s cubic-bezier(0.22, 1, 0.36, 1);
  padding: 0 6px;
  line-height: inherit;
}

/* @media screen and (max-width: 1080px) {
  .buttonPC {
    display: none;
  }
} */

@media (hover: hover) {
  .buttonPC:hover {
    opacity: 0.7;
    transition: opacity 0.4s cubic-bezier(0.22, 1, 0.36, 1);
  }
}

.buttonSP {
  display: none;
  background: #fff;
  border: 1px solid #eaeaea;
  border-radius: 8px;
  box-sizing: border-box;
  color: #333;
  cursor: pointer;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  height: 40px;
  margin: 24px auto 0;
  padding: 0 64px;
}

@media screen and (max-width: 1080px) {
  .buttonSP {
    display: flex;
  }
}

.buttonMoreBottom {
  display: flex;
  background: #fff;
  border: 1px solid #eaeaea;
  border-radius: 8px;
  box-sizing: border-box;
  color: #333;
  cursor: pointer;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  height: 40px;
  margin: 24px auto 0;
  padding: 0 64px;
}

.buttonMoreBottom:hover {
  /* background-color: ; */
}

.listWrap {
  position: relative;
}

@media (hover: hover) and (pointer: fine) {
  .listWrap:hover .scrollButton {
    display: flex;
  }
}

.scrollButton {
  display: none;
  position: absolute;
  top: 40px;
  width: 40px;
  height: 40px;
  background-color: #fff;
  border: 1px solid #c4c4c4;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 0;
  cursor: pointer;
}

.scrollButton svg {
  fill: #999;
}

.scrollButton:hover svg {
  fill: #333;
}

.scrollButton:disabled,
.scrollButton:hover:disabled {
  opacity: 0.4;
  background-color: #fff;
  border: 1px solid #c4c4c4;
  cursor: unset;
}

.scrollButton:hover:disabled svg {
  fill: #999;
}

.scrollLeft {
  composes: scrollButton;
  left: 4px;
  padding-right: 2px;
}

.scrollRight {
  composes: scrollButton;
  right: 4px;
  padding-left: 2px;
}

.list {
  list-style: none;
  margin: 16px 0 0;
  padding: 0;

  -ms-overflow-style: none;
  scrollbar-width: none;
}

.list::-webkit-scrollbar {
  display: none;
}

.listInline {
  composes: list;
  display: flex;
  overflow-y: visible;
  overflow-x: scroll;
}

.listInline[data-noscroll="true"] {
  overflow-x: hidden;
}

.listInline li {
  width: 200px;
  box-sizing: border-box;
  display: inline-block;
  flex-grow: 0;
  flex-shrink: 0;
  margin-bottom: 8px;
  cursor: pointer;
}

.listInline li + li {
  margin-left: 16px;
}

.listGrid {
  composes: list;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 16px;
}

@media screen and (max-width: 767px) {
  .listGrid li {
    margin-top: 16px;
  }
}

.link {
  text-decoration: unset;
  color: unset;
}

.thumbnail {
  width: 100%;
  padding-top: calc(56.25% - 2px);
  position: relative;
  overflow: hidden;
  background: #fff;
  border: solid 1px #fff;
  border-radius: 8px;
  box-sizing: border-box;
  position: relative;
}

.thumbnail img {
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
}

.blankThumbnail {
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;

  padding-top: 56.25%;
  background-color: #f1f1f1;
}

.price {
  color: #fff;
  background: rgba(0, 0, 0, 0.8);
  display: inline-block;
  font-size: 12px;
  font-weight: bold;
  margin: 0;
  padding: 4px 8px;
  position: absolute;
  right: 0;
  bottom: 8px;
}

.ttl {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  font-size: 14px;
  line-height: 140%;
  margin: 8px 0 0;
  opacity: 1;
  transition: opacity 0.4s cubic-bezier(0.22, 1, 0.36, 1);
}

.ttlSkeleton {
  composes: ttl;
  background-color: #f1f1f1;
}

.genre {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  color: #9281ff;
  font-size: 10px;
  line-height: 140%;
  margin: 4px 0 0;
  opacity: 1;
  transition: opacity 0.4s cubic-bezier(0.22, 1, 0.36, 1);
}

.genreSkeleton {
  composes: genre;
  background-color: #f1f1f1;
}

.name {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  color: #999;
  font-size: 10px;
  line-height: 140%;
  margin: 4px 0 0;
  opacity: 1;
  transition: opacity 0.4s cubic-bezier(0.22, 1, 0.36, 1);
}

.nameSkeleton {
  composes: name;
  background-color: #f1f1f1;
}

@media (hover: hover) {
  .list li:hover .thumbnail {
    border: solid 1px #9281ff;
    box-shadow: 0px 0px 8px rgba(146, 129, 255, 0.5);
    transition: 0.3s cubic-bezier(0.22, 1, 0.36, 1);
  }
  .list li:hover .ttl {
    opacity: 0.7;
    transition: opacity 0.4s cubic-bezier(0.22, 1, 0.36, 1);
  }
  .list li:hover .genre {
    opacity: 0.7;
    transition: opacity 0.4s cubic-bezier(0.22, 1, 0.36, 1);
  }
  .list li:hover .name {
    opacity: 0.7;
    transition: opacity 0.4s cubic-bezier(0.22, 1, 0.36, 1);
  }
}

.loading {
  margin: 24px 0;
}
