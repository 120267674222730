.container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.sidebar {
  box-sizing: border-box;

  min-width: 320px;
  margin: 64px 0 0;
  padding: 40px;
}

.sidebarList {
  padding: 0;
  margin: 0;
  list-style: none;
}

.sidebarItem {
  margin-top: 16px;
}
.sidebarItem:first-child {
  margin-top: 0;
}

.sidebarItem a {
  background: none;
  border-radius: 4px;
  box-sizing: border-box;
  color: #333;

  display: flex;
  align-items: center;
  text-decoration: none;

  width: 100%;
  height: 48px;
  padding: 0 16px;
}

.sidebarItem a[data-active="true"] {
  background: #f1f1f1;
  color: #9281ff;
  pointer-events: none;
}

.main {
  margin: 64px 0;
  padding: 16px;
  max-width: 680px;
  text-align: justify;
}

.main li,
.main p {
  margin: 8px 0;
  line-height: 1.6;
}

.main h2 {
  margin-top: 32px;
  line-height: 1.4;
}

.tar {
  font-size: 14px;
  text-align: right;
  margin-top: 32px;
}

@media (hover: hover) {
  .sidebarItem a:hover {
    color: #9281ff;
    transition: 0.4s cubic-bezier(0.25, 1, 0.5, 1);
  }
}

@media screen and (max-width: 768px) {
  .sidebar {
    box-sizing: border-box;
    min-width: 240px;
    margin-top: 32px;
    padding: 24px 0 24px 24px;
  }

  .main {
    margin-top: 32px;
    padding: 0 24px 24px;
  }
}

@media screen and (max-width: 540px) {
  .container {
    display: block;
  }

  .sidebar {
    margin: 32px 0;
    padding: 0 24px;
  }

  .sidebarList {
    display: flex;
    align-items: center;
  }

  .sidebarItem {
    margin: 0;
  }

  .main {
    box-sizing: border-box;
    width: 100%;
    margin: 0 0 64px;
    padding: 0 24px;
  }
}
