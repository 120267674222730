.container {
  box-sizing: border-box;
  outline: none;
}

.label {
  display: block;
  color: #333;
  font-size: 12px;
  font-weight: bold;
  text-align: left;
}

.drop {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #c4c4c4;
  border-radius: 8px;
  background: url(./BgAttachment.jpg) no-repeat;
  background-size: cover;
  cursor: pointer;
  margin-top: 16px;
  height: 160px;
  transition: 0.3s;
}

.drop:hover {
  border: solid 1px #9281ff;
  box-shadow: 0px 0px 8px rgba(146, 129, 255, 0.5);
  transition: 0.3s;
}

.center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.txt {
  font-size: 14px;
}

@media screen and (max-width: 768px) {
  .container {
    width: 100%;
  }
}
